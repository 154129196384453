import React, { FC } from 'react';
import {
  TextInput,
  FormDataConsumer,
  required,
  Validator,
  regex,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
// eslint-disable-next-line
// @ts-ignore
import { ColorInput } from 'react-admin-color-input';

const styles = makeStyles({
  customColorInput: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '300px !important',
  },
  colorSwatch: {
    width: '20px',
    height: '20px',
    margin: '37px 5px 14px',
  },
});

const colorValidator: Validator = (value: string) => {
  if (value.startsWith(' ')) {
    return 'Code should not be or start with a space.';
  }
  return null;
};
export const ColorCodeForm: FC = () => {
  const classes = styles();

  return (
    <div>
      <TextInput
        validate={[
          required(),
          regex(/^(?!\s)/, 'Name should not be or start with a space.'),
        ]}
        source="name"
      />
      <FormDataConsumer>
        {({ formData }) => (
          <span className={classes.customColorInput}>
            <ColorInput
              validate={[required('Field is required'), colorValidator]}
              source="code"
            />
            <span
              className={classes.colorSwatch}
              style={{
                background: formData.code,
              }}
            />
          </span>
        )}
      </FormDataConsumer>
    </div>
  );
};
