import React, { FC, useState } from 'react';
import {
  List,
  TextField,
  ListProps,
  Datagrid,
  ShowButton,
  NumberField,
  BooleanField,
  ReferenceField,
  FunctionField,
  Record,
  CreateButton,
  Pagination,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { RolePageProvider } from '../shared/RolePageProvider';
import CreateIcon from '@material-ui/icons/Create';
import { SearchBox } from '../calendar/SearchBox';
import { Notification } from '../shared/Notification';
import { PetFormModal } from '../shared/PetFormModal';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles({
  headerCell: {
    '&:nth-child(1)': {
      borderTop: '3px solid #257A8B',
      position: 'sticky',
      zIndex: 500,
      left: 0,
      background: 'white',
      borderRight: '1px solid #CFCFCF',
    },
    '&:last-child': {
      position: 'sticky',
      zIndex: 500,
      right: 0,
      background:
        'linear-gradient(270deg, #FAFAFA 65.08%, rgba(250, 250, 250, 0) 154.26%)',
    },
  },
  rowCell: {
    '&:nth-child(1)': {
      position: 'sticky',
      zIndex: 500,
      left: 0,
      background: 'white',
      borderRight: '1px solid #CFCFCF',
    },
    '&:last-child': {
      position: 'sticky',
      zIndex: 500,
      right: 0,
      background:
        'linear-gradient(270deg, #FAFAFA 65.08%, rgba(250, 250, 250, 0) 154.26%)',
    },
  },
  anchor: {
    textDecoration: 'none',
    color: '#257A8B',
  },
  actions: {
    marginRight: 0,
    marginBottom: '14px',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  // '& .MuiOutlinedInput-adornedEnd': {
  //   // padding: 0,
  //   padding: '0 20px 0 0',
  //   borderRadius: '20px',
  //   border: '1px solid',
  // },
});
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PostPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);
export const PetList: FC<ListProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  return (
    <RolePageProvider
      allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
    >
      <div className={classes.actions}>
        <CreateButton
          action={null}
          label="Create New"
          onClick={ev => {
            ev.preventDefault();
            setOpenFormDialog(true);
          }}
          style={{ marginBottom: '67px', float: 'right' }}
        />
        <div className={classes.title}>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Pet Medical Records
          </Typography>
          <SearchBox
            callback={text => {
              setTextSearch(text);
            }}
          />
        </div>
      </div>
      <List
        actions={false}
        basePath={'pets'}
        resource={'pets'}
        {...props}
        hasEdit={false}
        filter={{ searchTerm: textSearch, 'order[createdAt]': 'desc' }}
        hasShow={true}
        bulkActionButtons={false}
        pagination={<PostPagination />}
        title={'Pet Medical Records'}
      >
        <Datagrid classes={classes}>
          <FunctionField
            sortable={true}
            sortBy={'name'}
            label="Name"
            render={(record?: Record) => {
              return (
                <a
                  className={classes.anchor}
                  href={`#/pets/${encodeURIComponent(
                    record ? record.id : '',
                  )}/show`}
                >
                  {record?.name}
                </a>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="Last name"
            render={(record?: Record) => {
              return (
                <a
                  className={classes.anchor}
                  href={`#/pets/${encodeURIComponent(
                    record ? record.id : '',
                  )}/show`}
                >
                  {record?.lastname}
                </a>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="Species"
            render={(record?: Record) => {
              return (
                <>
                  {record?.species
                    ? record?.species.name
                    : record?.breed?.species.name}
                </>
              );
            }}
          />
          <ReferenceField
            label="Breed"
            source="breed[@id]"
            reference="breeds"
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="gender" sortable={false} />
          <NumberField source="age" sortable={false} />
          <NumberField source="weight" sortable={false} />
          <BooleanField source="deceased" sortable={false} />
          <ReferenceField
            sortable={false}
            label="Diagnosis"
            source="diagnosis[@id]"
            reference="diagnoses"
          >
            <TextField source="description" />
          </ReferenceField>
          <TextField source="referral" sortable={false} />
          <ShowButton
            label=""
            icon={<CreateIcon />}
            className={classes.anchor}
          />
        </Datagrid>
      </List>
      {openFormDialog && (
        <PetFormModal
          callback={({ message, success, id }) => {
            setOpenFormDialog(false);
            setSuccess(success);
            setSnackbarMessage(message);
            setOpenSnackbar(true);
            if (id) {
              history.push(`pets/${encodeURIComponent(id)}/show`);
            }
          }}
          closeCallback={() => setOpenFormDialog(false)}
          hidePlus={true}
        />
      )}
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </RolePageProvider>
  );
};
