import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Button, Typography } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { apiUrl } from '../../App';

const styles = makeStyles({
  layout: {
    height: '1px',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    justifyContent: 'flex-start',
    backgroundImage:
      'radial-gradient(circle at 50% 14em, #383746 0%, #022b3a 60%, #022b3a 100%)',
    backgroundRepeat: 'no-repeat',
  },
  profile: {
    minWidth: '300px',
    marginTop: '6em',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '10px',
    textAlign: 'center',
  },
  input: {
    marginBottom: '10px',
  },
});

const Reset: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidToken, setIsValidToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  const notify = useNotify();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { token } = useParams();
  useEffect(() => {
    const validateToken: () => void = async () => {
      const response = await fetch(`${apiUrl}/forgot_password/${token}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      });
      if (response.status === 200) {
        setIsValidToken(true);
      }
    };

    if (token) {
      validateToken();
    }
  }, [token]);

  const classes = styles();

  const handleRequestPasswordReset: () => void = async () => {
    const response = await fetch(`${apiUrl}/forgot_password/${token}`, {
      method: 'POST',
      body: JSON.stringify({ password: newPassword }),
    });
    if (response.status === 204) {
      notify('User Password Updated');
      setTimeout(() => history.push('/login'), 2500);
    } else if (response.status === 404) {
      notify('Invalid Reset Password token');
    }
  };

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();

    if (isValidToken) {
      handleRequestPasswordReset();
    }
  };

  return (
    <div className={classes.layout}>
      <div className={classes.profile}>
        <Typography variant="body1">
          <span>Setup your account.</span>
        </Typography>
        <form>
          {isValidToken ? (
            <>
              <FormControl
                className="form-control"
                variant="outlined"
                fullWidth
              >
                <InputLabel htmlFor="newPassword">New Password</InputLabel>
                <Input
                  required
                  className={classes.input}
                  key="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={event => setNewPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl
                className="form-control"
                variant="outlined"
                fullWidth
              >
                <InputLabel htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <Input
                  required
                  className={classes.input}
                  key="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={event => setConfirmPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                disabled={newPassword === '' || newPassword !== confirmPassword}
                disableRipple
                onClick={handleButtonClick}
                type="submit"
                variant="outlined"
              >
                Submit
              </Button>
            </>
          ) : (
            <Typography variant="body1" className="info">
              <span className="emphasize">Don&apos;t have an account?</span>
              <span>Reach out to your client success manager at Graybox.</span>
            </Typography>
          )}
        </form>
      </div>
    </div>
  );
};

export default Reset;
