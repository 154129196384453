import React, { useState } from 'react';
import { Consumer } from '../shared/types';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { makeStyles } from '@material-ui/core';
import { colorByEventType } from '../../utils/StringUtils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import { styled } from '@material-ui/core/styles';
interface Props {
  callback: Consumer<string[]>;
}
const styles = makeStyles(theme => {
  return {
    checkbox: {
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#000',
        padding: '0 25% 0 0',
        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1rem',
        },
      },
    },
    itemIcon: {
      width: '11px',
      height: '11px',
      borderRadius: '50%',
      marginRight: '7px',
    },
    holdIcon: {
      backgroundColor: colorByEventType('PlaceholderAppointmentEvent'),
    },
    aftercareIcon: {
      backgroundColor: colorByEventType('AftercareAppointmentEvent'),
    },
    cacIcon: {
      backgroundColor: colorByEventType('CacAppointmentEvent'),
    },
    breakIcon: {
      backgroundColor: colorByEventType('BreakEvent'),
    },
    defaultIcon: {
      background:
        'conic-gradient(#F6B34E, #ED6A69, #D879E0, #A0FBF0, #A2D978, #FDFF99)',
      '&::after': {
        display: 'block',
        width: '7px',
        height: '7px',
        content: '""',
        backgroundColor: '#fff',
        borderRadius: '50%',
        margin: 'auto',
        position: 'absolute',
        left: '18px',
        top: '16px',
        [theme.breakpoints.down('sm')]: {
          top: '17px',
        },
      },
    },
    mobileDropdownIcon: {
      lineHeight: '16px',
      color: 'rgba(0,0,0,1)',
      right: 0,
      position: 'absolute',
      pointerEvents: 'none',
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      },
    },
    mobileDropdownInput: {
      maxWidth: '38px',
    },
  };
});

export const AppointmentTypeSelector: React.FC<Props> = ({ callback }) => {
  const [appointmentType, setAppointmentType] = useState<string[]>([]);
  const classes = styles();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  // eslint-disable-next-line @typescript-eslint/ban-types
  const eventIcon = (eventName: string): string => {
    switch (eventName) {
      case 'Hold':
        return classes.holdIcon;
      case 'Break':
        return classes.breakIcon;
      case 'Aftercare':
        return classes.aftercareIcon;
      case 'CAC':
        return classes.cacIcon;
      default:
        return classes.defaultIcon;
    }
  };

  const MobileTypeSelectorIcon = () => {
    return (
      <span className={classes.mobileDropdownIcon}>
        <VisibilityOutlinedIcon />
        <ArrowDropDownOutlinedIcon />
      </span>
    );
  };

  return (
    <div id="appointment-field">
      <FormControl
        // variant="outlined"
        // margin="dense"
        style={
          matchesMobile
            ? {}
            : {
                width: '200px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }
        }
      >
        <TextField
          select
          label={!matchesMobile && 'Toggle View'}
          InputLabelProps={{
            style: {
              marginTop: 'unset',
              fontWeight: 400,
              letterSpacing: '0.00938em',
              color: '#0000008a',
              padding: 0,
              fontSize: '1rem',
              lineHeight: 1,
              position: 'absolute',
            },
            margin: matchesMobile ? undefined : 'dense',
            id: 'appointment-type-selector-label',
          }}
          SelectProps={{
            multiple: true,
            MenuProps: {
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            },
            onChange: event => {
              setAppointmentType(event.target.value as string[]);
              callback(event.target.value as string[]);
            },
            renderValue: (selected: unknown) =>
              (selected as string[]).join(', '),
            IconComponent: matchesMobile
              ? () => <MobileTypeSelectorIcon />
              : undefined,
            value: appointmentType,
            variant: 'standard',
            className: `appointment-type-selector${
              matchesMobile ? ' ' + classes.mobileDropdownInput : ''
            }`,
            style: { maxHeight: 38, minHeight: 38 },
          }}
        >
          {['Euthanasia', 'Aftercare', 'CAC', 'Hold', 'Break'].map(name => (
            <MenuItem key={name} value={name}>
              <div className={`${classes.itemIcon} ${eventIcon(name)}`} />
              <ListItemText primary={name} />
              <Checkbox
                checked={appointmentType.indexOf(name) > -1}
                icon={<></>}
                checkedIcon={<CheckOutlined />}
                className={classes.checkbox}
              />
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};
