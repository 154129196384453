import React, { FC, useState } from 'react';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  TopToolbar,
  Button,
} from 'react-admin';
import { CardHeader } from '@material-ui/core';
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import AddIcon from '@material-ui/icons/Add';
import { PetList } from './OwnerPetsList';
import { PetFormModal } from '../shared/PetFormModal';
import extractUUID from '../../utils/ExtractUUID';
import { Notification } from '../shared/Notification';

const PostShowActions: FC<ShowProps> = () => (
  <TopToolbar />
); /* Empty toolbar to remove core Edit and Show buttons */

export const OwnerPets: FC<ShowProps> = props => {
  const [openPetDialog, setOpenPetDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reload, setReload] = useState(false);
  return (
    <Show
      {...props}
      resource="clients"
      actions={<PostShowActions {...props} />}
    >
      <SimpleShowLayout className="xl-card-content">
        <Button
          onClick={() => {
            setOpenPetDialog(true);
          }}
          label="Add Pet"
          className="header-button"
        >
          <AddIcon />
        </Button>
        <CardHeader title="Pet Information" className="pink-header" />
        <PetList {...props} reload={reload} />
        {openPetDialog && (
          <PetFormModal
            callback={({ message, success }) => {
              setOpenPetDialog(false);
              setSuccess(success);
              setSnackbarMessage(message);
              setOpenSnackbar(true);
              setReload(!reload);
            }}
            closeCallback={() => setOpenPetDialog(false)}
            hidePlus={false}
            ownerId={extractUUID(props.id ?? '')}
          />
        )}
        <Notification
          close={() => {
            setOpenSnackbar(false);
          }}
          message={snackbarMessage}
          open={openSnackbar}
          success={success}
        />
      </SimpleShowLayout>
    </Show>
  );
};
