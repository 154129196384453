import React, { FC } from 'react';
import {
  List,
  TextField,
  ListProps,
  Datagrid,
  EditButton,
  ShowButton,
  Button,
  useNotify,
  BooleanField,
  ReferenceField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
import extractUUID from '../../utils/ExtractUUID';
import SendIcon from '@material-ui/icons/Send';
import { apiUrl } from '../../App';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ColorField } from 'react-admin-color-input';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const InviteUserButton = ({ record }) => {
  const notify = useNotify();

  const sendInvite = async () => {
    const formData = new FormData();
    formData.append('userId', extractUUID(record.id));
    const response = await fetch(`${apiUrl}/invite_users`, {
      method: 'POST',
      body: JSON.stringify({ userId: extractUUID(record.id) }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
    if (response.ok) {
      notify('User invitation sent successfully');
    } else {
      notify('There was an error with the request.');
    }
  };

  return (
    <Button onClick={sendInvite} label="Send Invite">
      <SendIcon />
    </Button>
  );
};

export const DoctorList: FC<ListProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <List {...props} hasEdit={true} hasShow={true} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="telephone" label="Phone" />
        <TextField source="initials" label="Initials" />
        <ReferenceField
          source="colorCode"
          reference="color_codes"
          label="Color Code"
        >
          <ColorField source="code" />
        </ReferenceField>
        <BooleanField source="deleted" label="Deleted" />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*@ts-ignore*/}
        <InviteUserButton />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  </RolePageProvider>
);
