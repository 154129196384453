import { Event } from '../components/shared/types';
import { get, patch, post } from './Api';
import moment from 'moment-timezone';
import { updateMemorialOptions } from './PetUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createEvent = async (event: Event): Promise<any> => {
  if (
    event['@type'] === 'Euthanasia' ||
    event['@type'] === 'Aftercare' ||
    event['@type'] === 'CAC'
  ) {
    if (event.owner?.id === undefined || event.owner.id === '') {
      const ownerBody = JSON.stringify({
        firstname: event.owner?.firstname,
        lastname: event.owner?.lastname,
        telephone: event.owner?.telephone,
        alternateTelephone: event.owner?.alternateTelephone,
        email: event.owner?.email,
        primary: true,
        addressLine1: event.owner?.addressLine1,
        location: event.owner?.location,
        state: event.owner?.state,
        city: event.owner?.city,
        postalCode: event.owner?.postalCode,
      });
      const ownerRes = await post({
        endpoint: 'owners',
        body: ownerBody,
      });
      event.owner = await ownerRes.json();
    }
    const options = event.pet?.memorialOptions;
    if (event.pet?.id === undefined || event.pet.id === '') {
      const petBody = JSON.stringify({
        name: event.pet?.name,
        lastname: event.pet?.lastname,
        gender: event.pet?.gender,
        age: parseInt(event.pet?.age.toString() ?? ''),
        weight: event.pet?.weight,
        deceased: false,
        breed: event.pet?.breed ? `/breeds/${event.pet?.breed?.id}` : null,
        species: event.pet?.species
          ? `/species/${event.pet?.species?.id}`
          : null,
        diagnosis: event.pet?.diagnosis
          ? `/diagnoses/${event.pet?.diagnosis?.id}`
          : null,
        veterinarians: event.pet?.veterinarians
          ? event.pet.veterinarians.map(_vet => `/veterinarians/${_vet.id}`)
          : [],
        referral: `${event.pet?.referral}`,
        owners: [`/owners/${event.owner?.id}`],
        petNotes: event.pet?.petNotes,
        // memorialOptions: event.pet?.memorialOptions,
      });
      const petRes = await post({
        endpoint: 'pets',
        body: petBody,
      });
      event.pet = await petRes.json();
    } else {
      const petBody = JSON.stringify({
        diagnosis: event.pet?.diagnosis
          ? `/diagnoses/${event.pet?.diagnosis?.id}`
          : null,
        veterinarians: event.pet.veterinarians
          ? event.pet.veterinarians.map(_vet => `/veterinarians/${_vet.id}`)
          : [],
        referral: `${event.pet?.referral}`,
        petNotes: event.pet?.petNotes,
        // memorialOptions: event.pet?.memorialOptions,
      });
      const petRes = await patch({
        endpoint: `pets/${event.pet.id}`,
        body: petBody,
      });
      event.pet = await petRes.json();
    }

    if (options !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      event.pet.memorialOptions = options;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await updateMemorialOptions(event.pet);
    // const petRes = await patch({
    //   endpoint: `pets/${event.pet.id}`,
    //   body: petBody,
    // });
  }
  switch (event['@type']) {
    case 'Euthanasia': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        transport: event.transport,
        lateCancelation: event.lateCancelation,
        mailing: event.mailing,
        witness: false,
        other: false,
        contactPreference: event.contactPreference,
        consultationCharge: event.consultationCharge.toString(),
        user: event.user ? 'users/' + event.user?.id : null,
        owner: 'owners/' + event.owner?.id,
        pet: 'pets/' + event.pet?.id,
        aftercareLocation: event.aftercareLocation
          ? 'aftercare_locations/' + event.aftercareLocation?.id
          : null,
        aftercare: event.aftercare ? 'aftercares/' + event.aftercare?.id : null,
        aftercareCharge: event.aftercareCharge?.toString(),
        otherCharge: event.otherCharge?.toString(),
        otherChargeDescription: event.otherChargeDescription,
        euthanasiaCharge: event.euthanasiaCharge?.toString(),
        transportCharge: event.transportCharge?.toString(),
        crematoryExtraCharge: event.crematoryExtraCharge?.toString(),
        doctorPayment: event.doctorPayment?.toString(),
        crematoryPayment: event.crematoryPayment?.toString(),
      });
      const euthanasiaEvent = await post({
        endpoint: 'euthanasia_appointment_events',
        body: eventBody,
      });
      return await euthanasiaEvent.json();
    }
    case 'Aftercare': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        transport: event.transport,
        lateCancelation: event.lateCancelation,
        mailing: event.mailing,
        witness: false,
        other: false,
        contactPreference: event.contactPreference,
        consultationCharge: event.consultationCharge.toString(),
        owner: 'owners/' + event.owner?.id,
        pet: 'pets/' + event.pet?.id,
        aftercareLocation: event.aftercareLocation
          ? 'aftercare_locations/' + event.aftercareLocation?.id
          : null,
        aftercare: event.aftercare ? 'aftercares/' + event.aftercare?.id : null,
        aftercareCharge: event.aftercareCharge?.toString(),
        otherCharge: event.otherCharge?.toString(),
        otherChargeDescription: event.otherChargeDescription,
        doctorPayment: event.doctorPayment?.toString(),
        crematoryPayment: event.crematoryPayment?.toString(),
      });
      const aftercareEvent = await post({
        endpoint: 'aftercare_appointment_events',
        body: eventBody,
      });
      return await aftercareEvent.json();
    }
    case 'CAC': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        mailing: event.mailing,
        witness: false,
        other: false,
        owner: 'owners/' + event.owner?.id,
        pet: 'pets/' + event.pet?.id,
        aftercareLocation: event.aftercareLocation
          ? 'aftercare_locations/' + event.aftercareLocation?.id
          : null,
        aftercare: event.aftercare ? 'aftercares/' + event.aftercare?.id : null,
        aftercareCharge: event.aftercareCharge?.toString(),
        otherCharge: event.otherCharge?.toString(),
        otherChargeDescription: event.otherChargeDescription,
        veterinarians: event.veterinarian
          ? `/veterinarians/${event.veterinarian?.id}`
          : null,
        doctorPayment: event.doctorPayment?.toString(),
        crematoryPayment: event.crematoryPayment?.toString(),
      });
      const cacEvent = await post({
        endpoint: 'cac_appointment_events',
        body: eventBody,
      });
      return await cacEvent.json();
    }
    case 'Hold': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        transport: false,
        mailing: false,
        witness: false,
        other: false,
        contactPreference: '',
        consultationCharge: '0.0',
        user: null,
      });
      const holdEvent = await post({
        endpoint: 'placeholder_appointment_events',
        body: eventBody,
      });
      return await holdEvent.json();
    }
    case 'Break': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        transport: false,
        mailing: false,
        witness: false,
        other: false,
        contactPreference: '',
        consultationCharge: '0.0',
        user: null,
      });
      const breakEvent = await post({
        endpoint: 'break_events',
        body: eventBody,
      });
      return await breakEvent.json();
    }
    case 'Duty': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: true,
        transport: false,
        mailing: false,
        witness: false,
        other: false,
        contactPreference: '',
        consultationCharge: '0.0',
        user: null,
      });
      const dutyEvent = await post({
        endpoint: 'duty_events',
        body: eventBody,
      });
      return await dutyEvent.json();
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteEvent = async (event: Event): Promise<any> => {
  let endpoint = '';
  switch (event['@type']) {
    case 'PlaceholderAppointmentEvent':
      endpoint = 'placeholder_appointment_events';
      break;
    case 'EuthanasiaAppointmentEvent':
      endpoint = 'euthanasia_appointment_events';
      break;
    case 'BreakEvent':
      endpoint = 'break_events';
      break;
    case 'AftercareAppointmentEvent':
      endpoint = 'aftercare_appointment_events';
      break;
    case 'CacAppointmentEvent':
      endpoint = 'cac_appointment_events';
      break;
    case 'DutyEvent':
      endpoint = 'duty_events';
      break;
  }
  const eventBody = JSON.stringify({
    deleted: true,
    deletedAt: moment(),
    deletedBy: `users/${localStorage.getItem('uid')}`,
  });
  return await patch({
    endpoint: `${endpoint}/${event.id}`,
    body: eventBody,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateEvent = async (event: Event): Promise<any> => {
  if (
    event['@type'] === 'Euthanasia' ||
    event['@type'] === 'CAC' ||
    event['@type'] === 'Aftercare'
  ) {
    if (event.owner?.id === undefined || event.owner.id === '') {
      const ownerBody = JSON.stringify({
        firstname: event.owner?.firstname,
        lastname: event.owner?.lastname,
        telephone: event.owner?.telephone,
        alternateTelephone: event.owner?.alternateTelephone,
        email: event.owner?.email,
        primary: true,
        addressLine1: event.owner?.addressLine1,
        location: event.owner?.location,
        state: event.owner?.state,
        city: event.owner?.city,
        postalCode: event.owner?.postalCode,
      });
      const ownerRes = await post({
        endpoint: 'owners',
        body: ownerBody,
      });
      event.owner = await ownerRes.json();
    }

    const petBody = JSON.stringify({
      diagnosis: event.pet?.diagnosis
        ? `/diagnoses/${event.pet?.diagnosis?.id}`
        : null,
      veterinarians: event.pet?.veterinarians
        ? event.pet.veterinarians.map(_vet => `/veterinarians/${_vet.id}`)
        : [],
      referral: `${event.pet?.referral}`,
      petNotes: event.pet?.petNotes,
    });

    const options = event.pet?.memorialOptions;

    if (event.pet?.id === undefined || event.pet.id === '') {
      const petRes = await post({
        endpoint: 'pets',
        body: petBody,
      });
      event.pet = await petRes.json();
    } else {
      const petRes = await patch({
        endpoint: `pets/${event.pet.id}`,
        body: petBody,
      });
      event.pet = await petRes.json();
    }

    if (options !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      event.pet.memorialOptions = options;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await updateMemorialOptions(event.pet);
  }
  switch (event['@type']) {
    case 'Euthanasia': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        transport: event.transport,
        lateCancelation: event.lateCancelation,
        mailing: event.mailing,
        petRecordsPage: event.petRecordsPage ?? false,
        witness: false,
        other: false,
        contactPreference: event.contactPreference,
        consultationCharge: event.consultationCharge.toString(),
        owner: 'owners/' + event.owner?.id,
        pet: 'pets/' + event.pet?.id,
        aftercareLocation: event.aftercareLocation
          ? 'aftercare_locations/' + event.aftercareLocation?.id
          : null,
        aftercare: event.aftercare ? 'aftercares/' + event.aftercare?.id : null,
        aftercareStatus: event.aftercareStatus
          ? 'aftercare_statuses/' + event.aftercareStatus?.id
          : null,
        aftercareCharge: event.aftercareCharge?.toString(),
        otherCharge: event.otherCharge?.toString(),
        otherChargeDescription: event.otherChargeDescription,
        euthanasiaCharge: event.euthanasiaCharge?.toString(),
        transportCharge: event.transportCharge?.toString(),
        crematoryExtraCharge: event.crematoryExtraCharge?.toString(),
        user: event.user ? 'users/' + event.user.id : null,
        doctorPayment: event.doctorPayment?.toString(),
        crematoryPayment: event.crematoryPayment?.toString(),
      });
      return await patch({
        endpoint: `euthanasia_appointment_events/${event.id}`,
        body: eventBody,
      });
    }
    case 'Aftercare': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        transport: event.transport,
        lateCancelation: event.lateCancelation,
        mailing: event.mailing,
        petRecordsPage: event.petRecordsPage ?? false,
        witness: false,
        other: false,
        contactPreference: event.contactPreference,
        consultationCharge: event.consultationCharge.toString(),
        owner: 'owners/' + event.owner?.id,
        pet: 'pets/' + event.pet?.id,
        aftercareLocation: event.aftercareLocation
          ? 'aftercare_locations/' + event.aftercareLocation?.id
          : null,
        aftercare: event.aftercare ? 'aftercares/' + event.aftercare?.id : null,
        aftercareStatus: event.aftercareStatus
          ? 'aftercare_statuses/' + event.aftercareStatus?.id
          : null,
        aftercareCharge: event.aftercareCharge?.toString(),
        otherCharge: event.otherCharge?.toString(),
        otherChargeDescription: event.otherChargeDescription,
        doctorPayment: event.doctorPayment?.toString(),
        crematoryPayment: event.crematoryPayment?.toString(),
      });
      return await patch({
        endpoint: `aftercare_appointment_events/${event.id}`,
        body: eventBody,
      });
    }
    case 'CAC': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        mailing: event.mailing,
        petRecordsPage: event.petRecordsPage ?? false,
        witness: false,
        other: false,
        owner: 'owners/' + event.owner?.id,
        pet: 'pets/' + event.pet?.id,
        aftercareLocation: event.aftercareLocation
          ? 'aftercare_locations/' + event.aftercareLocation?.id
          : null,
        aftercare: event.aftercare ? 'aftercares/' + event.aftercare?.id : null,
        aftercareStatus: event.aftercareStatus
          ? 'aftercare_statuses/' + event.aftercareStatus?.id
          : null,
        aftercareCharge: event.aftercareCharge?.toString(),
        otherCharge: event.otherCharge?.toString(),
        otherChargeDescription: event.otherChargeDescription,
        veterinarians: event.veterinarian
          ? `/veterinarians/${event.veterinarian?.id}`
          : null,
        doctorPayment: event.doctorPayment?.toString(),
        crematoryPayment: event.crematoryPayment?.toString(),
      });
      return await patch({
        endpoint: `cac_appointment_events/${event.id}`,
        body: eventBody,
      });
    }
    case 'Hold': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        transport: false,
        mailing: false,
        petRecordsPage: event.petRecordsPage ?? false,
        witness: false,
        other: false,
        contactPreference: '',
        consultationCharge: '0.0',
        user: null,
      });
      return patch({
        endpoint: `placeholder_appointment_events/${event.id}`,
        body: eventBody,
      });
    }
    case 'Break': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: false,
        transport: false,
        mailing: false,
        petRecordsPage: event.petRecordsPage ?? false,
        witness: false,
        other: false,
        contactPreference: '',
        consultationCharge: '0.0',
        user: null,
      });
      return patch({
        endpoint: `break_events/${event.id}`,
        body: eventBody,
      });
    }
    case 'Duty': {
      const eventBody = JSON.stringify({
        description: event.description,
        startTime: event.startTime,
        endTime: event.endTime,
        allDay: true,
        transport: false,
        mailing: false,
        petRecordsPage: event.petRecordsPage ?? false,
        witness: false,
        other: false,
        contactPreference: '',
        consultationCharge: '0.0',
        user: null,
      });
      return patch({
        endpoint: `duty_events/${event.id}`,
        body: eventBody,
      });
    }
  }
};

export const verifyDate = async (
  event: Event | undefined,
): Promise<boolean> => {
  if (event === undefined || event.user === undefined) return false;
  const futureDate = moment([]).add(7, 'days');
  const longDate = futureDate.diff(moment(event.startTime), 'days') <= 0;
  let busyDoctor = false;
  await get({
    endpoint: `events?role=${localStorage.getItem(
      'role',
    )}&startTime%5Bafter%5D=${event.startTime}&endTime%5Bbefore%5D=${
      event.endTime
    }&deleted=false&user.id=${event.user?.id}`,
  }).then(async _res => {
    const body = await _res.json();
    if (body['hydra:totalItems'] > 0) {
      busyDoctor = true;
    }
  });
  return longDate || busyDoctor;
};
