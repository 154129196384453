import React, { FC } from 'react';
import {
  Create,
  TextInput,
  CreateProps,
  SimpleForm,
  required,
  regex,
  BooleanInput,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const AftercareCodeCreate: FC<CreateProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Create {...props} resource="aftercares">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Code should not be or start with a space.'),
          ]}
          source="code"
        />
        <TextInput
          validate={[
            required(),
            regex(
              /^(?!\s)/,
              'Description should not be or start with a space.',
            ),
          ]}
          source="description"
        />
        <BooleanInput source="def" />
      </SimpleForm>
    </Create>
  </RolePageProvider>
);
