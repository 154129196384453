import React, { FC } from 'react';
import {
  Edit,
  TextInput,
  EditProps,
  SimpleForm,
  required,
  BooleanInput,
  regex,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const CitiesEdit: FC<EditProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Edit {...props} resource="cities">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Name should not be or start with a space.'),
          ]}
          source="name"
        />
        <TextInput
          source="county"
          validate={[
            regex(/^(?!\s)/, 'Name should not be or start with a space.'),
          ]}
        />
        <ReferenceInput
          label="State"
          source="state"
          reference="states"
          filterToQuery={() => ({ deleted: false, itemsPerPage: 100 })}
        >
          <SelectInput validate={[required()]} optionText="name" />
        </ReferenceInput>
        <BooleanInput source="deleted" />
      </SimpleForm>
    </Edit>
  </RolePageProvider>
);
