import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Consumer } from '../shared/types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

const styles = makeStyles({
  mobileDropdownIcon: {
    lineHeight: '16px',
    color: 'rgba(0,0,0,1)',
    right: 0,
    position: 'absolute',
    pointerEvents: 'none',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  mobileDropdownInput: {
    maxWidth: '38px',
  },
});
interface CreateNewEventButtonProps {
  changeViewCallback: Consumer<string | null>;
}

export const ToggleCalendarPeriod: React.FC<CreateNewEventButtonProps> = props => {
  const classes = styles();
  const [periodView, setPeriodView] = useState<string>('timeGridDay');
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const { changeViewCallback } = props;

  const MobileTypeSelectorIcon = () => {
    return (
      <span className={classes.mobileDropdownIcon}>
        <InsertInvitationIcon />
        <ArrowDropDownOutlinedIcon />
      </span>
    );
  };

  return (
    <div id="toggle-period-view">
      <FormControl
        variant="outlined"
        style={matchesMobile ? {} : { width: '200px' }}
      >
        <Select
          labelId="period-selector-label"
          id="period-selector"
          value={periodView}
          onChange={event => {
            setPeriodView(event.target.value as string);
            changeViewCallback(event.target.value as string);
          }}
          input={<Input />}
          renderValue={(selected: unknown) => selected as string}
          IconComponent={() => <MobileTypeSelectorIcon />}
          className={classes.mobileDropdownInput}
        >
          <MenuItem key="timeGridDay" value="timeGridDay">
            <ListItemText primary={'Day'} />
          </MenuItem>
          <MenuItem key="timeGridWeek" value="timeGridWeek">
            <ListItemText primary={'Week'} />
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
