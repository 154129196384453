import React, { useEffect, useState } from 'react';
import { AppointmentEventModel, OwnerModel } from '../../models';
import { get } from '../../utils/Api';
import { makeStyles } from '@material-ui/core';
import { AppointmentDetailsModal } from '../shared/AppointmentDetailsModal';
import { Event } from '../shared/types';

interface AppointmentHistoryProps {
  record?: OwnerModel;
}

const styles = makeStyles({
  appointmentsContainer: {
    margin: '30px',
    minHeight: '400px',
  },
  emptyContainer: {
    margin: '30px',
    minHeight: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headers: {
    textAlign: 'left' as const,
    color: '#000',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
    paddingBottom: '15px',
  },
  labels: {
    textAlign: 'left' as const,
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '15px',
    fontWeight: 700,
    marginBottom: '15px',
    display: 'flex',
  },
  emptyLabel: {
    textAlign: 'center',
    color: '#626262',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '18px',
    fontWeight: 400,
    maxWidth: '400px',
  },
  items: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px 0',
    color: '#626262',
  },
  link: {
    cursor: 'pointer',
    color: '#257A8B',
    textDecoration: 'underline',
    float: 'right',
  },
  table: {
    width: '100%',
    marginBottom: '45px',
    tableLayout: 'fixed',
  },
  detailsHeader: {
    width: '50px',
    textAlign: 'left' as const,
    color: '#000',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
    paddingBottom: '15px',
  },
  petName: {
    cursor: 'pointer',
    color: '#257A8B',
    textDecoration: 'underline',
  },
});

export const PetAppointmentHistory: React.FC<AppointmentHistoryProps> = ({
  record,
}) => {
  const [appointmentList, setAppointmentList] = useState<
    Array<AppointmentEventModel>
  >([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<Event | undefined>(
    undefined,
  );

  const classes = styles();

  useEffect(() => {
    get({
      endpoint: `aftercare_appointment_events?pet.id=${record?.originId}`,
    }).then(async response => {
      const dataRecords = await response.json();
      setAppointmentList(dataRecords['hydra:member']);
    });
  }, [record]);

  const viewDetails = (appointmentId: string) => {
    get({
      endpoint: `events/${appointmentId}`,
    }).then(async response => {
      const eventJson = await response.json();
      setSelectedEntry(eventJson as Event);
    });
    setOpenDialog(true);
  };

  const printAppointmentsTable = (
    appointments: Array<AppointmentEventModel>,
  ) => {
    const parseAppointmentType = (type: string) => {
      switch (type) {
        case 'EuthanasiaAppointmentEvent':
          return 'Euthanasia';
        case 'AftercareAppointmentEvent':
          return 'Aftercare';
        case 'PlaceholderAppointmentEvent':
          return 'Placeholder';
        default:
          return '';
      }
    };

    return (
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.headers}>Date</th>
            <th className={classes.headers}>Re: Pet</th>
            <th className={classes.headers}>Type</th>
            <th className={classes.detailsHeader}>Details</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map(appointment => {
            const appointmentDate = new Date(appointment.startTime || '');

            return (
              <tr key={`appointment-${appointment.id}`}>
                <td className={classes.items}>
                  {`${appointmentDate.toLocaleString('en-us', {
                    month: 'short',
                  })} ${appointmentDate.getDate()}, ${appointmentDate.getFullYear()}`}
                </td>
                <td className={classes.items}>
                  <label className={classes.petName}>{record?.name}</label>
                </td>
                <td className={classes.items}>
                  {parseAppointmentType(appointment['@type'])}
                </td>
                <td className={classes.items}>
                  <label
                    className={classes.link}
                    onClick={() => {
                      viewDetails(appointment.id);
                    }}
                  >
                    View
                  </label>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  if (appointmentList.length > 0) {
    return (
      <div className={classes.appointmentsContainer}>
        {printAppointmentsTable(appointmentList)}
        {openDialog && selectedEntry && (
          <AppointmentDetailsModal
            closeCallback={() => setOpenDialog(false)}
            callback={() => {
              setOpenDialog(false);
            }}
            editCallback={() => {}}
            event={selectedEntry}
            showActionLinks={false}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.emptyContainer}>
        <label className={classes.emptyLabel}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          To schedule an appointment select 'Create New' at the top, or head
          over to the Calendar tab.
        </label>
      </div>
    );
  }
};
