import React, { FC } from 'react';
import {
  List,
  TextField,
  BooleanField,
  ListProps,
  Datagrid,
  EditButton,
  ShowButton,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const AftercareCodeList: FC<ListProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <List {...props} hasEdit={true} hasShow={true} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="code" sortable={false} />
        <TextField source="description" sortable={false} />
        <BooleanField source="deleted" sortable={false} />
        <BooleanField source="def" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  </RolePageProvider>
);
