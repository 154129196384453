export const referralMap = [
  { id: 'Regular Veterinarian', name: 'Regular Veterinarian' },
  { id: 'Veterinarian - Other', name: 'Veterinarian - Other' },
  { id: 'Friend/Family', name: 'Friend/Family' },
  { id: 'Previous Client', name: 'Previous Client' },
  { id: 'Internet', name: 'Internet' },
  { id: 'Media (print/social/signage)', name: 'Media (print/social/signage)' },
  { id: 'Not Specified', name: 'Not Specified' },
  { id: 'Other', name: 'Other' },
];
