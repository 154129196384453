import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CustomTheme } from './components/shared/CustomTheme';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
  ResourceGuesser,
} from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import authProvider from './AuthProvider';
import { UserList } from './components/users/UserList';
import { UserCreate } from './components/users/UserCreate';
import { UserEdit } from './components/users/UserEdit';
import { UserShow } from './components/users/UserShow';
import { OwnerList } from './components/owners/OwnerList';
import { OwnerCreate } from './components/owners/OwnerCreate';
import { OwnerEdit } from './components/owners/OwnerEdit';
import { OwnerShow } from './components/owners/OwnerShow';
import { HouseholdList } from './components/households/HouseholdList';
import { HouseholdCreate } from './components/households/HouseholdCreate';
import { HouseholdEdit } from './components/households/HouseholdEdit';
import { HouseholdShow } from './components/households/HouseholdShow';
import CustomLayout from './components/shared/CustomLayout';
import Join from './components/users/Join';
import Reset from './components/users/Reset';
import Forgot from './components/users/Forgot';
import CustomLogin from './components/shared/CustomLogin';
import { DoctorList } from './components/doctors/DoctorList';
import { DoctorCreate } from './components/doctors/DoctorCreate';
import { DoctorEdit } from './components/doctors/DoctorEdit';
import { DoctorShow } from './components/doctors/DoctorShow';
import { ColorCodeEdit } from './components/colorCodes/ColorCodeEdit';
import { ColorCodeList } from './components/colorCodes/ColorCodeList';
import { ColorCodeCreate } from './components/colorCodes/ColorCodeCreate';
import { ColorCodeShow } from './components/colorCodes/ColorCodeShow';
import { CalendarView } from './components/calendar/CalendarView';
import { PetCreate } from './components/pets/PetCreate';
import { PetEdit } from './components/pets/PetEdit';
import { PetList } from './components/pets/PetList';
import { PetShow } from './components/pets/PetShow';
import { AftercareList } from './components/aftercare/AftercareList';
import { SpeciesCreate } from './components/species/SpeciesCreate';
import { SpeciesEdit } from './components/species/SpeciesEdit';
import { SpeciesList } from './components/species/SpeciesList';
import { SpeciesShow } from './components/species/SpeciesShow';
import { BreedCreate } from './components/breeds/BreedCreate';
import { BreedEdit } from './components/breeds/BreedEdit';
import { BreedList } from './components/breeds/BreedList';
import { BreedShow } from './components/breeds/BreedShow';
import { VeterinarianCreate } from './components/veterinarian/VeterinarianCreate';
import { VeterinarianEdit } from './components/veterinarian/VeterinarianEdit';
import { VeterinarianList } from './components/veterinarian/VeterinarianList';
import { VeterinarianShow } from './components/veterinarian/VeterinarianShow';
import { AftercareLocationCreate } from './components/aftercareLocation/AftercareLocationCreate';
import { AftercareLocationEdit } from './components/aftercareLocation/AftercareLocationEdit';
import { AftercareLocationList } from './components/aftercareLocation/AftercareLocationList';
import { AftercareLocationShow } from './components/aftercareLocation/AftercareLocationShow';
import { AftercareCodeCreate } from './components/aftercareCode/AftercareCodeCreate';
import { AftercareCodeEdit } from './components/aftercareCode/AftercareCodeEdit';
import { AftercareCodeList } from './components/aftercareCode/AftercareCodeList';
import { AftercareCodeShow } from './components/aftercareCode/AftercareCodeShow';
import { MemorialOptionCreate } from './components/memorialOptions/MemorialOptionCreate';
import { MemorialOptionEdit } from './components/memorialOptions/MemorialOptionEdit';
import { MemorialOptionList } from './components/memorialOptions/MemorialOptionList';
import { MemorialOptionShow } from './components/memorialOptions/MemorialOptionShow';
import { DiagnosisCreate } from './components/diagnoses/diagnosisCreate';
import { DiagnosisEdit } from './components/diagnoses/diagnosisEdit';
import { DiagnosisList } from './components/diagnoses/diagnosisList';
import { DiagnosisShow } from './components/diagnoses/diagnosisShow';
import { CrematoryReport } from './components/reports/CrematoryReport';
import { PayReport } from './components/reports/PayReport';
import { StatesList } from './components/states/StatesList';
import { StatesCreate } from './components/states/StatesCreate';
import { StatesEdit } from './components/states/StatesEdit';
import { StatesShow } from './components/states/StatesShow';
import { CitiesList } from './components/cities/CitiesList';
import { CitiesCreate } from './components/cities/CitiesCreate';
import { CitiesEdit } from './components/cities/CitiesEdit';
import { CitiesShow } from './components/cities/CitiesShow';
import { CustomReports } from './components/reports/CustomReports';
import { ScorecardDataReport } from './components/reports/ScorecardDataReport';

export let apiUrl = process.env.REACT_APP_API_ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
  apiUrl = window._env_.API_URL;
}

const fetchHeaders = {
  Authorization: `Bearer ${window.localStorage.getItem('token')}`,
};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
  });
const apiDocumentationParser = entrypoint =>
  parseHydraDocumentation(entrypoint, {
    headers: new Headers(fetchHeaders),
  }).then(
    ({ api }) => ({ api }),
    result => {
      window.localStorage.removeItem('token');
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              <Route
                key="rootKey"
                path="/"
                render={() => {
                  return window.localStorage.getItem('token') ? (
                    window.location.reload()
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />,
            ],
          });

        default:
          return Promise.reject(result);
      }
    },
  );
const dataProvider = baseHydraDataProvider(
  apiUrl,
  fetchHydra,
  apiDocumentationParser,
  true,
);

// eslint-disable-next-line react/display-name,@typescript-eslint/explicit-module-boundary-types
export default () => (
  <HydraAdmin
    dataProvider={dataProvider}
    authProvider={authProvider}
    entrypoint={apiUrl}
    customRoutes={[
      <Route key="joinPageKey" path="/join/:token" component={Join} noLayout />,
      <Route
        key="resetPageKey"
        path="/reset_password/:token"
        component={Reset}
        noLayout
      />,
      <Route
        key="forgotPageKey"
        path="/forgot_password"
        component={Forgot}
        noLayout
      />,
      <Route key="calendar" path="/calendar" component={CalendarView} />,
      <Route
        key="crematoryCharges"
        path="/crematory-charges"
        component={CrematoryReport}
      />,
      <Route key="pay-slip" path="/pay-slip" component={PayReport} />,
      <Route
        key="customReport"
        path="/custom-data-export"
        component={CustomReports}
      />,
      <Route
        key="scorecardDataReport"
        path="/scorecard-report"
        component={ScorecardDataReport}
      />,
      <Route
        key="client_information_list"
        path="/client-information"
        component={OwnerList}
      />,
      <Route
        key="pet_medical_records_list"
        path="/pet-medical-records"
        component={PetList}
      />,
      <Route
        key="aftercare_list"
        path="/aftercare"
        component={AftercareList}
      />,
    ]}
    theme={CustomTheme}
    layout={CustomLayout}
    loginPage={CustomLogin}
  >
    <ResourceGuesser
      name="users"
      list={UserList}
      create={UserCreate}
      edit={UserEdit}
      show={UserShow}
    />
    <ResourceGuesser
      name="doctors"
      list={DoctorList}
      create={DoctorCreate}
      edit={DoctorEdit}
      show={DoctorShow}
    />
    <ResourceGuesser
      name="aftercares"
      list={AftercareCodeList}
      create={AftercareCodeCreate}
      edit={AftercareCodeEdit}
      show={AftercareCodeShow}
    />
    <ResourceGuesser name="aftercare_appointment_events" list={AftercareList} />
    <ResourceGuesser
      name="color_codes"
      list={ColorCodeList}
      create={ColorCodeCreate}
      edit={ColorCodeEdit}
      show={ColorCodeShow}
    />
    <ResourceGuesser
      name="owners"
      list={OwnerList}
      create={OwnerCreate}
      edit={OwnerEdit}
      show={OwnerShow}
    />
    <ResourceGuesser
      name="households"
      list={HouseholdList}
      create={HouseholdCreate}
      edit={HouseholdEdit}
      show={HouseholdShow}
    />
    <ResourceGuesser
      name="pets"
      list={PetList}
      create={PetCreate}
      edit={PetEdit}
      show={PetShow}
    />
    <ResourceGuesser
      name="species"
      list={SpeciesList}
      create={SpeciesCreate}
      edit={SpeciesEdit}
      show={SpeciesShow}
    />
    <ResourceGuesser
      name="breeds"
      list={BreedList}
      create={BreedCreate}
      edit={BreedEdit}
      show={BreedShow}
    />
    <ResourceGuesser
      name="veterinarians"
      list={VeterinarianList}
      create={VeterinarianCreate}
      edit={VeterinarianEdit}
      show={VeterinarianShow}
    />
    <ResourceGuesser
      name="aftercare_locations"
      list={AftercareLocationList}
      create={AftercareLocationCreate}
      edit={AftercareLocationEdit}
      show={AftercareLocationShow}
    />

    <ResourceGuesser
      name="memorial_options"
      list={MemorialOptionList}
      create={MemorialOptionCreate}
      edit={MemorialOptionEdit}
      show={MemorialOptionShow}
    />
    <ResourceGuesser
      name="diagnoses"
      list={DiagnosisList}
      create={DiagnosisCreate}
      edit={DiagnosisEdit}
      show={DiagnosisShow}
    />
    <ResourceGuesser
      name="states"
      list={StatesList}
      create={StatesCreate}
      edit={StatesEdit}
      show={StatesShow}
    />
    <ResourceGuesser
      name="cities"
      list={CitiesList}
      create={CitiesCreate}
      edit={CitiesEdit}
      show={CitiesShow}
    />
  </HydraAdmin>
);
