import React, { FC, useState } from 'react';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  TopToolbar,
  Button,
} from 'react-admin';
import { CardHeader } from '@material-ui/core';
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import AddIcon from '@material-ui/icons/Add';
import extractUUID from '../../utils/ExtractUUID';
import { Notification } from '../shared/Notification';
import { ClientFormModal } from '../shared/ClientFormModal';
import { PetOwnersList } from './PetOwnersList';
import { makeStyles } from '@material-ui/core/styles';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { post } from '../../utils/Api';
import { downloadDoc } from '../../utils/StringUtils';

const formStyles = makeStyles({
  topBar: {
    justifyContent: 'flex-end',
  },
});

const PostShowActions: FC<ShowProps> = props => {
  const classes = formStyles();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <TopToolbar className={classes.topBar}>
      <Button
        label="Generate Referral Letter"
        alignIcon="right"
        onClick={async () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          for (const _vet of props.data.veterinarians) {
            const file = await post({
              endpoint: 'reports/referral-letter',
              body: JSON.stringify({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                petId: extractUUID(props.id),
                vetId: _vet.id,
              }),
            });
            if (file.ok) {
              if (file.status === 205) {
                setSuccess(false);
                setSnackbarMessage(
                  'The selected pet should have at least an appointment to generate a referral letter.',
                );
                setOpenSnackbar(true);
              } else {
                await downloadDoc(file, 'referral-letter.docx');
              }
            } else {
              setSuccess(false);
              setSnackbarMessage(
                'There was an error generating the referral letter.',
              );
              setOpenSnackbar(true);
            }
          }
        }}
      >
        <SaveAlt />
      </Button>
      <Button
        label="Generate Cremation Letter"
        alignIcon="right"
        onClick={async () => {
          const file = await post({
            endpoint: 'reports/cremation-letter',
            body: JSON.stringify({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              petId: extractUUID(props.id),
            }),
          });
          if (file.ok) {
            if (file.status === 205) {
              setSuccess(false);
              setSnackbarMessage(
                'The selected pet should have at least an appointment to generate a cremation letter.',
              );
              setOpenSnackbar(true);
            } else {
              await downloadDoc(file, 'cremation-letter.docx');
            }
          } else {
            setSuccess(false);
            setSnackbarMessage(
              'There was an error generating the cremation letter.',
            );
            setOpenSnackbar(true);
          }
        }}
      >
        <SaveAlt />
      </Button>
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </TopToolbar>
  );
}; /* Empty toolbar to remove core Edit and Show buttons */

export const PetOwners: FC<ShowProps> = props => {
  const [openPetDialog, setOpenPetDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reload, setReload] = useState(false);
  return (
    <Show
      {...props}
      resource="clients"
      actions={<PostShowActions {...props} />}
    >
      <SimpleShowLayout className="xl-card-content">
        <Button
          onClick={() => {
            setOpenPetDialog(true);
          }}
          label="Add Client"
          className="header-button"
        >
          <AddIcon />
        </Button>
        <CardHeader title="Client Information" className="blue-header" />
        <PetOwnersList {...props} reload={reload} />
        {openPetDialog && (
          <ClientFormModal
            callback={({ message, success }) => {
              setOpenPetDialog(false);
              setSuccess(success);
              setSnackbarMessage(message);
              setOpenSnackbar(true);
              setReload(!reload);
            }}
            closeCallback={() => setOpenPetDialog(false)}
            hidePlus={false}
            petId={extractUUID(props.id ?? '')}
          />
        )}
        <Notification
          close={() => {
            setOpenSnackbar(false);
          }}
          message={snackbarMessage}
          open={openSnackbar}
          success={success}
        />
      </SimpleShowLayout>
    </Show>
  );
};
