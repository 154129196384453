import React, { FC } from 'react';
import {
  Show,
  BooleanField,
  ShowProps,
  SimpleShowLayout,
  TextField,
  NumberField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const MemorialOptionShow: FC<ShowProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="code" addLabel={true} />
        <TextField source="description" addLabel={true} />
        <NumberField
          source="price"
          options={{ style: 'currency', currency: 'USD' }}
        />
        <BooleanField source="deleted" addLabel={true} />
      </SimpleShowLayout>
    </Show>
  </RolePageProvider>
);
