import React, { FC } from 'react';
import { RoleLinkProps } from './types';

export const RoleLinkProvider: FC<RoleLinkProps> = ({ allowed, children }) => {
  const role = localStorage.getItem('role');
  if (
    role === null ||
    allowed.find(_allowedRole => _allowedRole === role) === undefined
  ) {
    return <></>;
  } else {
    return <>{children}</>;
  }
};
