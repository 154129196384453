import React, { FC, useState } from 'react';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  TopToolbar,
  Button,
} from 'react-admin';
import { CardHeader } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { RolePageProvider } from '../shared/RolePageProvider';
import { PetEdit } from './PetEdit';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { makeStyles } from '@material-ui/core/styles';

const formStyles = makeStyles({
  topBar: {
    justifyContent: 'flex-start',
  },
});

const PostShowActions: FC<ShowProps> = () => {
  const classes = formStyles();
  const history = useHistory();
  return (
    <TopToolbar className={classes.topBar}>
      <Button label="Back to Listing" onClick={() => history.push('/pets')}>
        <ArrowBackIcon />
      </Button>
    </TopToolbar>
  );
}; /* Empty toolbar to remove core Edit and Show buttons */
export const PetInformationForm: FC<ShowProps> = props => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  return (
    <RolePageProvider
      allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
    >
      <Show {...props} resource="pets" actions={<PostShowActions {...props} />}>
        <SimpleShowLayout className="xl-card-content">
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
            label="Edit"
            className="header-button"
          >
            <EditIcon />
          </Button>
          <CardHeader title="Pet Information" className="blue-header" />
          <PetEdit
            {...props}
            editing={isEditing}
            disableEditing={() => setIsEditing(false)}
          />
        </SimpleShowLayout>
      </Show>
    </RolePageProvider>
  );
};
