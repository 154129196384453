import React, { FC } from 'react';
import {
  Edit,
  TextInput,
  EditProps,
  SimpleForm,
  SelectInput,
  BooleanInput,
  required,
  regex,
  email,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
import { RoleLinkProvider } from '../shared/RoleLinkProvider';

export const UserEdit: FC<EditProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}>
    <Edit {...props} resource="users">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Name should not be or start with a space.'),
          ]}
          source="name"
        />
        <TextInput validate={[required(), email()]} source="email" />
        <TextInput source="telephone" label="Phone" />
        <RoleLinkProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
          <SelectInput
            validate={[required()]}
            source="role"
            label="User Role"
            choices={[
              { id: 'ROLE_ADMIN', name: 'Administrator User' },
              { id: 'ROLE_STAFF', name: 'Staff User' },
            ]}
          />
        </RoleLinkProvider>
        <TextInput source="newPassword" type="password" />
        <BooleanInput source="deleted" />
      </SimpleForm>
    </Edit>
  </RolePageProvider>
);
