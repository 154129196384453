import React, { FC, forwardRef, useState } from 'react';
import {
  AppBar,
  Layout,
  UserMenu,
  MenuItemLink,
  LayoutProps,
} from 'react-admin';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import { Settings, Event } from '@material-ui/icons/';
import SVGLogo from './SVGLogo';
import { RoleLinkProvider } from './RoleLinkProvider';
import { RolePageProvider } from './RolePageProvider';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});
const StyledMenuItemLink = styled(MenuItemLink)({
  paddingLeft: 40,
  fontWeight: 700,
  letterSpacing: 0,
});

const ProfileMenu = forwardRef(() => (
  <MenuItemLink
    to={`/users/%2F${localStorage.getItem('uType')}s%2F${localStorage.getItem(
      'uid',
    )}`}
    primaryText="Profile"
    leftIcon={<Settings />}
    sidebarIsOpen={true}
  />
));
ProfileMenu.displayName = 'ProfileMenu';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MyUserMenu = props => (
  <UserMenu {...props}>
    <RolePageProvider
      allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
    >
      <ProfileMenu />
    </RolePageProvider>
  </UserMenu>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CustomAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <Typography variant="h6" color="inherit" className={classes.title}>
        {''}
      </Typography>
      <SVGLogo color="#FFFFFF" height="50px" width="160px" />
      <span className={classes.spacer} />
    </AppBar>
  );
};
const SideBar = () => {
  const [reportsExpanded, setReportsExpanded] = useState(false);
  const [adminExpanded, setAdminExpanded] = useState(false);

  return (
    <>
      <RoleLinkProvider
        allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
      >
        <StyledMenuItemLink
          leftIcon={<Event />}
          key="calendar"
          to="/calendar"
          primaryText="Calendar"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
      <RoleLinkProvider
        allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
      >
        <StyledMenuItemLink
          leftIcon={<Event />}
          key="aftercare"
          to="/aftercare"
          primaryText="Aftercare"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
      <RoleLinkProvider
        allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
      >
        <StyledMenuItemLink
          leftIcon={<Event />}
          key="petMedicalRecords"
          to="/pet-medical-records"
          primaryText="Pet Medical Records"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
      <RoleLinkProvider
        allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
      >
        <StyledMenuItemLink
          leftIcon={<Event />}
          key="clientInformation"
          to="/owners"
          primaryText="Client Information"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
      <RoleLinkProvider allowed={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']}>
        <Accordion expanded={reportsExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => setReportsExpanded(_expanded => !_expanded)}
          >
            <Typography>Reports</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <StyledMenuItemLink
                key="paySlip"
                to="/pay-slip"
                primaryText="Pay Slip"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="crematoryCharges"
                to="/crematory-charges"
                primaryText="Crematory Charges"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="customDataExport"
                to="/custom-data-export"
                primaryText="Custom Data Export"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="scorecardReport"
                to="/scorecard-report"
                primaryText="Scorecard Data Report"
                sidebarIsOpen
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </RoleLinkProvider>
      <RoleLinkProvider allowed={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']}>
        <Accordion expanded={adminExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => setAdminExpanded(_expanded => !_expanded)}
          >
            <Typography>Administration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <StyledMenuItemLink
                key="doctors"
                to="/doctors"
                primaryText="Doctors"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="users"
                to="/users"
                primaryText="Users"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="aftercares"
                to="/aftercares"
                primaryText="Aftercare Codes"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="aftercareLocations"
                to="/aftercare_locations"
                primaryText="Aftercare Locations"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="memorialOptions"
                to="/memorial_options"
                primaryText="Memorial Options"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="petSpecies"
                to="/species"
                primaryText="Pet Species"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="petBreeds"
                to="/breeds"
                primaryText="Pet Breeds"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="diagnoses"
                to="/diagnoses"
                primaryText="Diagnoses"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="veterinarians"
                to="/veterinarians"
                primaryText="Veterinarians"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="colorCodes"
                to="/color_codes"
                primaryText="Color Codes"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="states"
                to="/states"
                primaryText="States"
                sidebarIsOpen
              />
              <StyledMenuItemLink
                key="cities"
                to="/cities"
                primaryText="Cities"
                sidebarIsOpen
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </RoleLinkProvider>
    </>
  );
};

const CustomLayout: FC = (props: LayoutProps) => (
  <Layout {...props} appBar={CustomAppBar} menu={SideBar} />
);
CustomLayout.displayName = 'CustomLayout';

export default CustomLayout;
