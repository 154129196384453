import { Client } from '../components/shared/types';
import { patch } from './Api';
import extractUUID from './ExtractUUID';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateOwner = async (client: Client): Promise<any> => {
  const ownerBody = JSON.stringify({
    firstname: client.firstname,
    lastname: client.lastname,
    telephone: client.telephone,
    alternateTelephone: client.alternateTelephone,
    email: client.email,
    addressLine1: client.addressLine1,
    location: client.location,
    state: client.state,
    city: client.city,
    postalCode: client.postalCode,
  });
  return await patch({
    endpoint: `owners/${extractUUID(client.id)}`,
    body: ownerBody,
  });
};
