import React, { FC } from 'react';
import {
  Show,
  TextField,
  ShowProps,
  SimpleShowLayout,
  ReferenceField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const HouseholdShow: FC<ShowProps> = props => (
  <RolePageProvider
    allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
    redirectTo="/landing"
  >
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="displayName" addLabel={true} />
        <TextField
          source="addressLine1"
          label="Address Line 1"
          addLabel={true}
        />
        <TextField
          source="addressLine2"
          label="Address Line 2"
          addLabel={true}
        />
        <TextField source="city" addLabel={true} />
        <TextField source="state" addLabel={true} />
        <TextField source="location" addLabel={true} />
        <TextField source="postalCode" label="Zip" addLabel={true} />
        <ReferenceField
          label="Primary Contact"
          source="primaryContact"
          reference="owners"
        >
          <TextField source="firstname" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  </RolePageProvider>
);
