import { AftercareLog, Event, Pet } from '../components/shared/types';

export const formatTopic = (event: Event): string => {
  let topic = '';
  let species = event.pet?.species
    ? event.pet?.species?.name
    : event.pet?.breed?.species
    ? event.pet?.breed?.species.name
    : '';

  species = species === 'Feline' ? 'F, ' : ' ';
  switch (event['@type']) {
    case 'BreakEvent':
      topic = '[Break] ' + event.description;
      break;
    case 'DutyEvent':
      topic = '[Duty] ' + event.description;
      break;
    case 'PlaceholderAppointmentEvent':
      topic = '[Hold] ' + event.description;
      break;
    case 'CacAppointmentEvent':
      topic = '[CAC] ' + event.description;
      break;
    case 'EuthanasiaAppointmentEvent':
      topic =
        'Euth [' +
        (event.contactPreference === 'None' ? '' : event.contactPreference) +
        '] ' +
        '[' +
        (event.user ? event.user?.initials : '') +
        '] ' +
        '[' +
        event.pet?.name +
        ' ' +
        (event.pet?.primaryOwner
          ? event.pet?.primaryOwner?.lastname
          : event.owner?.lastname) +
        '] ' +
        '[' +
        species +
        event.pet?.weight +
        ' lb] ' +
        '[' +
        (event.aftercare?.code ?? '') +
        '] ' +
        '[' +
        event.owner?.location +
        ']';
      break;
    case 'AftercareAppointmentEvent':
      topic =
        'AO [' +
        (event.contactPreference === 'None' ? '' : event.contactPreference) +
        '] ' +
        '[' +
        (event.user ? event.user?.initials : '') +
        '] ' +
        '[' +
        event.pet?.name +
        ' ' +
        (event.pet?.primaryOwner
          ? event.pet?.primaryOwner?.lastname
          : event.owner?.lastname) +
        '] ' +
        '[' +
        species +
        event.pet?.weight +
        ' lb] ' +
        '[' +
        (event.aftercare?.code ?? '') +
        '] ' +
        '[' +
        event.owner?.location +
        ']';
    // case 'EuthanasiaAppointmentEvent':
    //   topic =
    //     'Euthanasia [' +
    //     event.contactPreference +
    //     '], ' +
    //     '[TO ' +
    //     (event.transport ? 'Y' : 'N') +
    //     '], ' +
    //     '[' +
    //     event.description +
    //     '], ' +
    //     '[' +
    //     event.user?.initials +
    //     '], ' +
    //     '[' +
    //     event.pet?.name +
    //     ' ' +
    //     event.pet?.lastname +
    //     '], ' +
    //     '[' +
    //     event.pet?.weight +
    //     ' lb], ' +
    //     '[' +
    //     event.aftercare?.code +
    //     '], ' +
    //     '[' +
    //     event.aftercareLocation?.code +
    //     ']';
    //   break;
    // case 'AftercareAppointmentEvent':
    //   topic =
    //     'Aftercare [' +
    //     event.contactPreference +
    //     '], ' +
    //     '[TO ' +
    //     (event.transport ? 'Y' : 'N') +
    //     '], ' +
    //     '[' +
    //     event.description +
    //     '], ' +
    //     '[' +
    //     event.user?.initials +
    //     '], ' +
    //     '[' +
    //     event.pet?.name +
    //     ' ' +
    //     event.pet?.lastname +
    //     '], ' +
    //     '[' +
    //     event.pet?.weight +
    //     'lb], ' +
    //     '[' +
    //     event.aftercare?.code +
    //     '], ' +
    //     '[' +
    //     event.aftercareLocation?.code +
    //     ']';
  }
  return topic;
};

export const formatColor = (event: Event): string => {
  return (
    colorByEventType(event['@type']) || (event.user?.colorCode?.code ?? '')
  );
};

export const colorByEventType = (eventType: string): string => {
  let color = '';
  switch (eventType) {
    case 'BreakEvent':
      color = '#8CD940';
      break;
    case 'DutyEvent':
      color = '#52E20E';
      break;
    case 'PlaceholderAppointmentEvent':
      color = '#D98C3F';
      break;
    case 'AftercareAppointmentEvent':
      color = '#9CFD3F';
      break;
    case 'CacAppointmentEvent':
      color = '#3788D8';
      break;
    default:
      color = '';
  }
  return color;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const formatLog = (
  previousPet: Pet,
  newPet: Pet,
  previousLog: AftercareLog,
  newLog: AftercareLog,
): string => {
  let log = '';
  if (previousLog.aftercareStatus?.id !== newLog.aftercareStatus?.id) {
    log += `Changed status from ${previousLog.aftercareStatus?.description} to ${newLog.aftercareStatus?.description}`;
  }
  if (previousLog.aftercareLocation?.id !== newLog.aftercareLocation?.id) {
    log += log === '' ? '' : ', ';
    log += `Changed location from ${previousLog.aftercareLocation?.name} to ${newLog.aftercareLocation?.name}`;
  }
  if (previousLog.aftercare?.id !== newLog.aftercare?.id) {
    log += log === '' ? '' : ', ';
    log += `Changed aftercare from ${previousLog.aftercare?.code} to ${newLog.aftercare?.code}`;
  }
  previousPet.memorialOptions.forEach(_memOpt => {
    const newOpt = newPet.memorialOptions.find(
      _newMemOpt => _newMemOpt.id === _memOpt.id,
    );
    if (newOpt !== undefined && _memOpt.amount !== newOpt.amount) {
      log += log === '' ? '' : ', ';
      log += `Changed memorial option ${_memOpt.memorialOption?.code} from ${_memOpt.amount} to ${newOpt.amount}`;
    }
  });
  log += '.';
  return log;
};

export const formatNewLog = (pet: Pet, newLog: AftercareLog): string => {
  let log = '';
  if (newLog.aftercareStatus) {
    log += `Status-> ${newLog.aftercareStatus?.description}.`;
  }
  if (newLog.aftercareLocation) {
    log += log === '' ? '' : ', ';
    log += `Location-> ${newLog.aftercareLocation?.name}`;
  }
  if (newLog.aftercare) {
    log += log === '' ? '' : ', ';
    log += `Aftercare-> ${newLog.aftercare?.code}`;
  }
  pet.memorialOptions.forEach(_memOpt => {
    if (_memOpt.amount !== 0) {
      log += log === '' ? '' : ', ';
      log += `Memorial option ${_memOpt.memorialOption?.code} - ${_memOpt.amount}`;
    }
  });
  log += '.';
  return log;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const downloadCSV = async (file: Response, name: string) => {
  const fileText = await file.text();
  const a = document.createElement('a');

  a.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(fileText),
  );
  a.setAttribute('download', name);
  a.click();
  return;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const downloadZip = async (file: Response, name: string) => {
  const fileText = await file.blob();
  const a = document.createElement('a');

  a.href = window.URL.createObjectURL(fileText);
  a.setAttribute('download', name);
  a.click();
  return;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const downloadDoc = async (file: Response, name: string) => {
  const fileText = await file.blob();
  const a = document.createElement('a');

  a.href = window.URL.createObjectURL(fileText);
  a.setAttribute('download', name);
  a.click();
  return;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeNonNumeric = function (text: string) {
  return text.replace(/[^0-9]/g, '');
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatPhoneNumber = function (
  rawPhoneNumber: string,
  setNumber: CallableFunction,
) {
  const phoneNumberNumeric = removeNonNumeric(rawPhoneNumber);

  const areaCode = phoneNumberNumeric.substring(0, 3);
  const firstThree = phoneNumberNumeric.substring(3, 6);
  const lastFour = phoneNumberNumeric.substring(6, 10);

  let phoneNumber = phoneNumberNumeric;
  if (phoneNumberNumeric.length > 6) {
    phoneNumber = `(${areaCode}) ${firstThree}-${lastFour}`;
  } else if (phoneNumberNumeric.length > 3) {
    phoneNumber = `(${areaCode}) ${firstThree}`;
  } else if (phoneNumberNumeric.length > 0) {
    phoneNumber = `(${areaCode}`;
  }

  setNumber(phoneNumber);
};
