import React, { FC, useState } from 'react';
import { useNotify, Notification } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { Button, Typography } from '@material-ui/core';
import { apiUrl } from '../../App';
import SVGLogo from '../shared/SVGLogo';

const styles = makeStyles({
  layout: {
    height: '1px',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    justifyContent: 'flex-start',
    backgroundImage:
      'radial-gradient(circle at 50% 14em, #383746 0%, #022b3a 60%, #022b3a 100%)',
    backgroundRepeat: 'no-repeat',
  },
  profile: {
    minWidth: '300px',
    marginTop: '6em',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '10px',
    textAlign: 'center',
  },
  input: {
    marginBottom: '10px',
  },
  notify: {
    justifyContent: 'center',
    background: '#2196f3',
  },
});

const Forgot: FC = () => {
  const classes = styles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const notify = useNotify();

  const handleRequestPasswordReset: (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => Promise<void> = async e => {
    e.preventDefault();
    const response = await fetch(`${apiUrl}/forgot_password/`, {
      method: 'POST',
      body: JSON.stringify({ email }),
    });
    if (response?.status === 204) {
      notify(
        'If your email address matches one in our account records we will email you a reset link. Please check your email.',
        'info',
      );
      setTimeout(() => history.push('/login'), 3500);
    } else if (response.status === 404) {
      notify('Invalid Email', 'error');
    }
  };

  return (
    <div className={classes.layout}>
      <div className={classes.profile}>
        <Typography variant="body1">
          <SVGLogo color="#000" height="50px" width="160px" />
        </Typography>
        <form>
          <FormControl className="form-control" variant="outlined" fullWidth>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              className={classes.input}
              name="email"
              type="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </FormControl>
          <Button
            disabled={email === ''}
            disableRipple
            onClick={handleRequestPasswordReset}
            type="submit"
            variant="outlined"
          >
            Submit
          </Button>
        </form>
      </div>
      <Notification
        className={`${classes.notify} notification info`}
        type="info"
        autoHideDuration={3000}
      />
    </div>
  );
};

export default Forgot;
