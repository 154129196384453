import React, { FC, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

import { post } from '../../utils/Api';
import { Moment } from 'moment';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { downloadZip } from '../../utils/StringUtils';
import { Notification } from '../shared/Notification';

const baseStyles = makeStyles({
  reportContainer: {
    marginTop: '98px',
    marginLeft: '35px',
    '&DateInput_input DateInput_input_1': {
      backgroundColor: '#0000000D',
    },
  },
  label: {
    marginBottom: '10.19px',
    marginTop: '22px',
  },
  SingleDatePicker: {
    marginRight: '20px',
  },
  button: {
    marginTop: '40px',
    position: 'relative',
    padding: '10px 36px',
  },
  checks: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const CustomReports: FC = () => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [goToStartDateFocus, setGoToStartDateFocus] = useState(false);
  const [goToEndDateFocus, setGoToEndDateFocus] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [monthEnd, setMonthEnd] = useState(false);
  const [aftercareClinics, setAftercareClinics] = useState(false);
  const [fasData, setFasData] = useState(false);
  const [aftercareOnly, setAftercareOnly] = useState(false);
  const [mammothFile, setMammothFile] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);

  const classes = baseStyles();

  const getReport = async () => {
    const file = await post({
      endpoint: 'reports/custom',
      body: JSON.stringify({
        startDate: startDate?.format('Y-M-D') + 'T00:00:00',
        endDate: endDate?.format('Y-M-D') + 'T23:59:59',
        aftercareOnly: aftercareOnly,
        fasData: fasData,
        aftercareClinics: aftercareClinics,
        monthEnd: monthEnd,
        mammothFile: mammothFile,
      }),
    });
    if (file.ok) {
      await downloadZip(file, 'custom-reports.zip');
    } else {
      setSuccess(false);
      setSnackbarMessage('There was an error generating the report(s).');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (
      startDate !== null &&
      endDate !== null &&
      (aftercareOnly || fasData || aftercareClinics || mammothFile || monthEnd)
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [
    aftercareClinics,
    aftercareOnly,
    endDate,
    fasData,
    mammothFile,
    monthEnd,
    startDate,
  ]);

  return (
    <>
      <div className={classes.reportContainer}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Custom Data Export
        </Typography>
        <InputLabel className={classes.label}>Date Range</InputLabel>
        <SingleDatePicker
          date={startDate}
          onDateChange={date => setStartDate(date)}
          focused={goToStartDateFocus}
          onFocusChange={({ focused }) => setGoToStartDateFocus(focused)}
          displayFormat="MMM-DD-YYYY"
          id={'goToDate'}
          numberOfMonths={1}
          isOutsideRange={() => false}
          placeholder="Start Date"
        />
        <SingleDatePicker
          date={endDate}
          onDateChange={date => setEndDate(date)}
          focused={goToEndDateFocus}
          onFocusChange={({ focused }) => setGoToEndDateFocus(focused)}
          displayFormat="MMM-DD-YYYY"
          id={'goToDate'}
          numberOfMonths={1}
          isOutsideRange={() => false}
          placeholder="End Date"
        />
        <InputLabel className={classes.label}>Data Sets</InputLabel>
        <div className={classes.checks}>
          <FormControlLabel
            control={
              <Checkbox
                checked={monthEnd}
                onChange={() => setMonthEnd(!monthEnd)}
                name="checkedB"
                color="primary"
              />
            }
            label="Month-end Data"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={aftercareClinics}
                onChange={() => setAftercareClinics(!aftercareClinics)}
                name="checkedB"
                color="primary"
              />
            }
            label="Client Aftercare Clinics"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={fasData}
                onChange={() => setFasData(!fasData)}
                name="checkedB"
                color="primary"
              />
            }
            label="FAS Data"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={aftercareOnly}
                onChange={() => setAftercareOnly(!aftercareOnly)}
                name="checkedB"
                color="primary"
              />
            }
            label="Aftercare Data (AO)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={mammothFile}
                onChange={() => setMammothFile(!mammothFile)}
                name="checkedB"
                color="primary"
              />
            }
            label="All Inclusive Report"
          />
        </div>

        <div>
          <Button
            disabled={!buttonEnabled}
            variant="contained"
            className={classes.button}
            onClick={getReport}
          >
            Generate Report
          </Button>
        </div>
      </div>
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </>
  );
};
