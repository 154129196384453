import React, { FC, useState } from 'react';
import {
  List,
  TextField as TableField,
  ListProps,
  Datagrid,
  ShowButton,
  FunctionField,
  CreateButton,
  Pagination,
} from 'react-admin';

import { useHistory } from 'react-router-dom';
import { Record } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import { RolePageProvider } from '../shared/RolePageProvider';
import { ClientFormModal } from '../shared/ClientFormModal';
import { Notification } from '../shared/Notification';
import CreateIcon from '@material-ui/icons/Create';
import { SearchBox } from '../calendar/SearchBox';
import { Typography } from '@material-ui/core';
const minWidth = () => {
  const firstEl = document?.getElementById('id_0');
  return firstEl ? firstEl.clientWidth : 100;
};

const useStyles = makeStyles({
  table: {
    borderTop: '1px solid #CFCFCF',
  },
  headerCell: {
    whiteSpace: 'nowrap',
    '&:nth-child(1)': {
      borderTop: '3px solid #257A8B',
      position: 'sticky',
      zIndex: 500,
      left: 0,
      background: 'white',
    },
    '&:nth-child(2)': {
      borderTop: '3px solid #257A8B',
      position: 'sticky',
      zIndex: 500,
      left: minWidth(),
      background: 'white',
      borderRight: '1px solid #CFCFCF',
    },
    '&:last-child': {
      position: 'sticky',
      zIndex: 500,
      right: 0,
      background:
        'linear-gradient(270deg, #FAFAFA 65.08%, rgba(250, 250, 250, 0) 154.26%)',
    },
  },
  rowCell: {
    whiteSpace: 'nowrap',
    '&:nth-child(1)': {
      position: 'sticky',
      zIndex: 500,
      left: 0,
      background: 'white',
    },
    '&:nth-child(2)': {
      position: 'sticky',
      zIndex: 500,
      left: minWidth(),
      background: 'white',
      borderRight: '1px solid #CFCFCF',
    },
    '&:last-child': {
      position: 'sticky',
      zIndex: 500,
      right: 0,
      background:
        'linear-gradient(270deg, #FAFAFA 65.08%, rgba(250, 250, 250, 0) 154.26%)',
    },
  },
  anchor: {
    textDecoration: 'none',
    color: '#257A8B',
  },
  actions: {
    marginRight: 0,
    marginBottom: '14px',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PostPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const OwnerList: FC<ListProps> = props => {
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const history = useHistory();
  const classes = useStyles();
  return (
    <RolePageProvider
      allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
    >
      <div className={classes.actions}>
        <CreateButton
          action={null}
          label="Create New"
          onClick={ev => {
            ev.preventDefault();
            setOpenFormDialog(true);
          }}
          style={{ marginBottom: '67px', float: 'right' }}
        />
        <div className={classes.title}>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Client Information
          </Typography>
          <SearchBox callback={setTextSearch} />
        </div>
      </div>
      <List
        {...props}
        bulkActionButtons={false}
        basePath={'owners'}
        resource={'owners'}
        filter={{ searchTerm: textSearch, 'order[createdAt]': 'desc' }}
        hasEdit={true}
        hasShow={false}
        actions={false}
        pagination={<PostPagination />}
      >
        <Datagrid classes={classes}>
          <FunctionField
            sortable={true}
            sortBy={'firstname'}
            label="First name"
            render={(record?: Record) => {
              return (
                <a
                  className={classes.anchor}
                  href={`#/owners/${encodeURIComponent(
                    record ? record.id : '',
                  )}/show`}
                >
                  {record?.firstname}
                </a>
              );
            }}
          />
          <FunctionField
            sortable={true}
            sortBy={'lastname'}
            label="Last name"
            render={(record?: Record) => {
              return (
                <a
                  className={classes.anchor}
                  href={`#/owners/${encodeURIComponent(
                    record ? record.id : '',
                  )}/show`}
                >
                  {record?.lastname}
                </a>
              );
            }}
          />
          <TableField label="Phone" source="telephone" sortable={false} />
          <TableField
            label="Secondary Phone"
            source="alternateTelephone"
            sortable={false}
          />
          <TableField source="email" sortable={false} />
          <TableField source="addressLine1" sortable={false} />
          <TableField source="city" sortable={false} />
          <TableField source="state" sortable={false} />
          <TableField source="postalCode" sortable={false} />
          <ShowButton
            label=""
            icon={<CreateIcon />}
            className={classes.anchor}
          />
        </Datagrid>
      </List>
      {openFormDialog && (
        <ClientFormModal
          callback={({ message, success, id }) => {
            setOpenFormDialog(false);
            setSuccess(success);
            setSnackbarMessage(message);
            setOpenSnackbar(true);
            if (id) {
              history.push(`owners/${encodeURIComponent(id)}/show`);
            }
          }}
          closeCallback={() => setOpenFormDialog(false)}
          hidePlus={true}
        />
      )}
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </RolePageProvider>
  );
};
