import React, { FC } from 'react';
import {
  Create,
  TextInput,
  CreateProps,
  SimpleForm,
  SelectInput,
  required,
  regex,
  email,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const UserCreate: FC<CreateProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Create {...props}>
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Name should not be or start with a space.'),
          ]}
          source="name"
        />
        <TextInput validate={[required(), email()]} source="email" />
        <TextInput source="telephone" label="Phone" />
        <SelectInput
          validate={[required()]}
          source="role"
          label="User Role"
          choices={[
            { id: 'ROLE_ADMIN', name: 'Administrator User' },
            { id: 'ROLE_STAFF', name: 'Staff User' },
          ]}
        />
        <TextInput
          validate={[required()]}
          source="newPassword"
          type="password"
        />
      </SimpleForm>
    </Create>
  </RolePageProvider>
);
