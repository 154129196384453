import React, { FC, useEffect, useState } from 'react';
import {
  List,
  TextField,
  ListProps,
  Datagrid,
  ReferenceField,
  FunctionField,
  Record,
  Pagination,
  Button,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Aftercare, Event } from '../shared/types';
import {
  MenuItem,
  Typography,
  Select,
  Checkbox,
  FormControl,
} from '@material-ui/core';
import { RolePageProvider } from '../shared/RolePageProvider';
import CreateIcon from '@material-ui/icons/Create';
import { SearchBox } from '../calendar/SearchBox';
import { Notification } from '../shared/Notification';
import { AftercareStatus, PetMemorialOption } from '../shared/types';
import { get, patch } from '../../utils/Api';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useLocation } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles({
  headerCell: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    '&:nth-child(1)': {
      borderTop: '3px solid #257A8B',
      position: 'sticky',
      zIndex: 500,
      left: 0,
      background: 'white',
      boxShadow: '5px 2px 5px grey',
      borderRight: '1px solid #CFCFCF',
    },
    '&:last-child': {
      position: 'sticky',
      zIndex: 500,
      right: 0,
      background:
        'linear-gradient(270deg, #FAFAFA 65.08%, rgba(250, 250, 250, 0) 154.26%)',
    },
  },
  rowCell: {
    '&:nth-child(1)': {
      position: 'sticky',
      zIndex: 500,
      left: 0,
      background: 'white',
      boxShadow: '5px 2px 5px grey',
      borderRight: '1px solid #CFCFCF',
    },
    '&:last-child': {
      position: 'sticky',
      zIndex: 500,
      right: 0,
      background:
        'linear-gradient(270deg, #FAFAFA 65.08%, rgba(250, 250, 250, 0) 154.26%)',
    },
  },
  anchor: {
    textDecoration: 'none',
    color: '#257A8B',
  },
  editButton: {
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
  actions: {
    // marginLeft: 'auto',
    marginRight: 0,
    marginBottom: '15px',
    '& .MuiInputBase-root input:not(#searchEntries)': {
      backgroundColor: '#FFF',
      borderRadius: '30px',
      padding: '15px 25px',
    },
    '& #search-field': {
      marginRight: '10px',
    },
  },
  statusLabel: {
    padding: '4px 12px',
    width: '100%',
    borderRadius: '2px',
    fontWeight: 400,
    fontSize: '0.85rem',
    display: 'flex',
    letterSpacing: '0.5px',
  },
  statusItem: {
    padding: '7px 11px',
    margin: 0,
    justifyContent: 'center',
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: 'rgba(56, 54, 70, 0.1) !important',
    },
  },
  statusDropdown: {
    width: '100%',
  },
  statusFilter: {
    width: '145px',
    border: '1px solid',
    borderRadius: '30px',
    '& .MuiSelect-select.MuiSelect-select': {
      backgroundColor: '#FFF',
      borderRadius: '30px',
      padding: '10px 13px',
    },
    '& .MuiInputBase-input': {
      fontSize: '0.95rem',
    },
    '&.MuiInput-underline:after': {
      display: 'none',
    },
    '&.MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiSelect-icon': {
      right: '10px',
    },
  },
  statusFilterItem: {
    '&.MuiMenuItem-root': {
      fontSize: '0.75rem',
      fontWeight: 400,
      paddingLeft: '20px',
    },
  },
  filtersContainer: {
    // display: 'flex',
    width: '100%',
    '& .MuiOutlinedInput-adornedEnd': {
      // padding: 0,
      padding: '0 20px 0 0',
      borderRadius: '20px',
      // border: '1px solid',
    },
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiInputBase-root input:not(#searchEntries)': {
      backgroundColor: '#FFF',
      borderRadius: '30px',
      padding: '15px 25px',
    },
    '& #search-field': {
      marginRight: '10px',
    },
  },
});
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PostPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const sendUpdateRequest = async (
  eventId: string,
  statusId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const eventBody = JSON.stringify({
    aftercareStatus: `/aftercare_statuses/${statusId}`,
  });
  return await patch({
    endpoint: eventId.replace(/^\/+/, ''),
    body: eventBody,
  });
};

export const AftercareList: FC<ListProps> = props => {
  const classes = useStyles();
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [statuses, setStatuses] = useState<AftercareStatus[]>([]);
  const [aftercares, setAftercares] = useState<Aftercare[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [selectedAftercares, setSelectedAftercares] = useState<string[]>([]);
  const search = useLocation().search;

  useEffect(() => {
    get({ endpoint: `aftercare_statuses?order%5BsortOrder%5D=asc` }).then(
      async response => {
        const jsonStatuses = await response.json();
        setStatuses(jsonStatuses['hydra:member']);
      },
    );
  }, []);

  useEffect(() => {
    get({ endpoint: `aftercares?order%5BsortOrder%5D=asc` }).then(
      async response => {
        const jsonStatuses = await response.json();
        setAftercares(jsonStatuses['hydra:member']);
      },
    );
  }, []);

  const updateAftercareEventStatus = (
    record: Record | undefined,
    statusId: string,
  ) => {
    sendUpdateRequest(record?.id as string, statusId).then(r => {
      if (!r.ok) {
        setSuccess(false);
        setSnackbarMessage('There was an error while updating the status.');
        setOpenSnackbar(true);
      } else {
        setSuccess(true);
        setSnackbarMessage('Status updated successfully!');
        if (record) {
          record.aftercareStatus.id = statusId;
        }
      }
      setOpenSnackbar(true);
    });
  };
  const exportAftercare = () => {
    const page = new URLSearchParams(search).get('page') ?? 1;
    const perPage = new URLSearchParams(search).get('perPage') ?? 10;
    get({
      endpoint: `aftercare_appointment_events?order%5BupdatedAt%5D=desc&searchTerm=${textSearch}&searchStatus${statusSearch}&searchAftercare=${
        selectedAftercares.length > 0
          ? selectedAftercares
              .filter(_after => _after !== 'Aftercare Filter')
              .join(',')
          : ''
      }&page=${page}&itemsPerPage=${perPage}`,
    }).then(async response => {
      const jsonData = await response.json();
      const aftercareData = jsonData['hydra:member'];
      const headers = [
        'Name,Status,Aftercare Location,Species,Date of Death,Weight,CAC,Aftercare,CP Total,IP Total, NP Total',
      ];
      const csv = aftercareData.reduce((acc: string[], event: Event) => {
        acc.push(
          [
            event.pet?.name,
            event.aftercareStatus?.description,
            event.aftercareLocation?.name,
            event.pet?.breed?.species.name,
            event.pet?.dateOfDeath
              ? new Date(event.pet?.dateOfDeath).toLocaleDateString()
              : '',
            event.pet?.weight,
            event['@type'] === 'CacAppointmentEvent',
            event.aftercare?.description,
            event.pet?.memorialOptions.filter(
              (item: PetMemorialOption) => item.memorialOption?.code === 'CP',
            ).length,
            event.pet?.memorialOptions.filter(
              (item: PetMemorialOption) => item.memorialOption?.code === 'IP',
            ).length,
            event.pet?.memorialOptions.filter(
              (item: PetMemorialOption) => item.memorialOption?.code === 'NP',
            ).length,
          ].join(','),
        );
        return acc;
      }, []);
      const finalData = [...headers, ...csv].join('\n');
      const a = document.createElement('a');

      a.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(finalData),
      );
      a.setAttribute('download', 'aftercare.csv');
      a.click();
    });
  };

  useEffect(() => {
    if (aftercares.length > 0) {
      const localAfters: string[] = [];
      aftercares.forEach(aftercare => {
        if (aftercare.def) {
          localAfters.push(aftercare.description);
        }
      });
      setSelectedAftercares(localAfters);
    }
  }, [aftercares]);

  useEffect(() => {
    if (selectedAftercares.length === 0) {
      setSelectedAftercares(['Aftercare Filter']);
    }
  }, [selectedAftercares.length]);
  return (
    <RolePageProvider
      allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
    >
      <div className={classes.actions}>
        <div className={classes.filtersContainer}>
          <Button
            label="Export"
            alignIcon="left"
            onClick={exportAftercare}
            style={{ marginBottom: '67px', float: 'right' }}
          >
            <GetAppIcon />
          </Button>
          <div className={classes.title}>
            <Typography
              variant="h5"
              style={{ fontWeight: 700, marginRight: 'auto' }}
            >
              Aftercare
            </Typography>
            <SearchBox
              callback={text => {
                setTextSearch(text);
              }}
            />
            <Select
              id="status-filter"
              label="Status Filter"
              onChange={event => {
                setStatusFilter(event.target.value as string);
                setStatusSearch(event.target.value as string);
              }}
              className={classes.statusFilter}
              displayEmpty
              value={statusFilter}
            >
              <MenuItem
                key={''}
                value={''}
                className={classes.statusFilterItem}
              >
                <span>Status Filter</span>
              </MenuItem>
              {statuses.map(status => {
                return (
                  <MenuItem
                    key={status.description}
                    value={status.description}
                    className={classes.statusFilterItem}
                  >
                    <span>{status.description}</span>
                  </MenuItem>
                );
              })}
            </Select>
            <FormControl>
              <Select
                id="aftercare-filter"
                multiple
                labelId={'demo-simple-select-label'}
                label="Aftercare Filter"
                onChange={event => {
                  setSelectedAftercares(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    typeof event.target.value === 'string'
                      ? event.target.value.split(',')
                      : event.target.value,
                  );
                }}
                className={classes.statusFilter}
                value={selectedAftercares}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                renderValue={(selected: string[]) => {
                  if (selected.length > 1) {
                    return selected
                      .filter(_after => _after !== 'Aftercare Filter')
                      .join(', ');
                  }
                  return selected.join(', ');
                }}
              >
                {aftercares.map(aftercare => {
                  return (
                    <MenuItem
                      key={aftercare.description}
                      value={aftercare.description}
                      className={classes.statusFilterItem}
                    >
                      <Checkbox
                        checked={
                          selectedAftercares.indexOf(aftercare.description) > -1
                        }
                      />
                      <span>{aftercare.description}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <List
        actions={false}
        basePath={'aftercare_appointment_events'}
        resource={'aftercare_appointment_events'}
        {...props}
        hasEdit={false}
        filter={{
          searchTerm: textSearch,
          searchStatus: statusSearch,
          searchAftercare:
            selectedAftercares.length > 0
              ? selectedAftercares
                  .filter(_after => _after !== 'Aftercare Filter')
                  .join(',')
              : '',
          'order[updatedAt]': 'desc',
          deleted: false,
        }}
        hasShow={true}
        bulkActionButtons={false}
        pagination={<PostPagination />}
        title={'Aftercare Appointment Events'}
      >
        <Datagrid classes={classes}>
          <FunctionField
            sortable={true}
            sortBy={'pet.name'}
            label="Name"
            render={(record?: Record) => {
              return (
                <a
                  className={classes.anchor}
                  href={`#/pets/%2Fpets%2F${encodeURIComponent(
                    record?.pet ? record?.pet.id : '',
                  )}/show`}
                >
                  {`${record?.pet.name} ${record?.pet.lastname ?? ''}`}
                </a>
              );
            }}
          />
          <FunctionField
            sortable={true}
            sortBy={'aftercareStatus.description'}
            label="Status"
            render={(record?: Record) => {
              return (
                <Select
                  id="status-select"
                  value={record?.aftercareStatus?.id ?? ''}
                  label="Label"
                  onChange={event => {
                    updateAftercareEventStatus(
                      record,
                      event.target.value as string,
                    );
                  }}
                  className={classes.statusDropdown}
                >
                  {statuses.map(status => {
                    return (
                      <MenuItem
                        key={status.id}
                        value={status.id}
                        className={classes.statusItem}
                      >
                        <span
                          className={classes.statusLabel}
                          style={{
                            backgroundColor: `#${status.code}26`,
                            color: `#${status.code}`,
                          }}
                        >
                          {status.description}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              );
            }}
          />
          <TextField
            label="Aftercare Location"
            source="aftercareLocation[name]"
            sortable={true}
            sortBy={'aftercareLocation.name'}
          />
          <ReferenceField
            label="Species"
            source="pet[breed[species[@id]]]"
            reference="species"
            sortable={true}
            sortBy={'pet.breed.species.name'}
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            sortable={true}
            sortBy={'pet.dateOfDeath'}
            label="DoD"
            render={(record?: Record) => {
              return (
                <span>{`${
                  record?.pet.dateOfDeath
                    ? new Date(record?.pet.dateOfDeath).toLocaleDateString()
                    : ''
                }`}</span>
              );
            }}
          />
          <FunctionField
            sortable={true}
            sortBy={'pet.weight'}
            label="Weight"
            render={(record?: Record) => {
              return <span>{`${record?.pet.weight}lbs`}</span>;
            }}
          />
          <FunctionField
            sortable={false}
            label="CAC"
            render={(record?: Record) => {
              if (record && record['@type'] === 'CacAppointmentEvent')
                return <CheckIcon />;
              else return <ClearIcon />;
            }}
          />
          <TextField
            label="Aftercare"
            source="aftercare[description]"
            sortable={true}
            sortBy={'aftercare.description'}
          />
          <FunctionField
            sortable={false}
            label="CP Total"
            render={(record?: Record) => {
              return (
                <span>{`${
                  record?.pet.memorialOptions.filter(
                    (item: PetMemorialOption) =>
                      item.memorialOption?.code === 'CP',
                  ).length > 0
                    ? record?.pet.memorialOptions.filter(
                        (item: PetMemorialOption) =>
                          item.memorialOption?.code === 'CP',
                      )[0].amount
                    : 0
                }`}</span>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="IP Total"
            render={(record?: Record) => {
              return (
                <span>{`${
                  record?.pet.memorialOptions.filter(
                    (item: PetMemorialOption) =>
                      item.memorialOption?.code === 'IP',
                  ).length > 0
                    ? record?.pet.memorialOptions.filter(
                        (item: PetMemorialOption) =>
                          item.memorialOption?.code === 'IP',
                      )[0].amount
                    : 0
                }`}</span>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label="NP Total"
            render={(record?: Record) => {
              return (
                <span>{`${
                  record?.pet.memorialOptions.filter(
                    (item: PetMemorialOption) =>
                      item.memorialOption?.code === 'NP',
                  ).length > 0
                    ? record?.pet.memorialOptions.filter(
                        (item: PetMemorialOption) =>
                          item.memorialOption?.code === 'NP',
                      )[0].amount
                    : 0
                }`}</span>
              );
            }}
          />
          <FunctionField
            sortable={false}
            label=""
            render={(record?: Record) => {
              return (
                <a
                  className={`${classes.anchor} ${classes.editButton}`}
                  href={`#/pets/%2Fpets%2F${encodeURIComponent(
                    record?.pet ? record?.pet.id : '',
                  )}/show`}
                >
                  <CreateIcon />
                </a>
              );
            }}
          />
        </Datagrid>
      </List>
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </RolePageProvider>
  );
};
