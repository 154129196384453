import React, { FC, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { AftercareLocation } from '../shared/types';
import { ReportsProvider } from './useReportContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { get, post } from '../../utils/Api';
import { Moment } from 'moment';
import { downloadCSV } from '../../utils/StringUtils';
import { Notification } from '../shared/Notification';

const baseStyles = makeStyles({
  reportContainer: {
    marginTop: '98px',
    marginLeft: '35px',
    '&DateInput_input DateInput_input_1': {
      backgroundColor: '#0000000D',
    },
  },
  label: {
    marginBottom: '10.19px',
    marginTop: '22px',
  },
  SingleDatePicker: {
    marginRight: '20px',
  },
  button: {
    marginTop: '40px',
    position: 'relative',
    padding: '10px 36px',
  },
});

const Report: FC = () => {
  const [
    aftercareLocation,
    setAftercareLocation,
  ] = useState<AftercareLocation | null>(null);
  const [aftercareLocations, setAftercareLocations] = useState<
    AftercareLocation[]
  >([]);
  const [
    aftercareLocationSearchText,
    setAftercareLocationSearchText,
  ] = useState<string>('');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [goToStartDateFocus, setGoToStartDateFocus] = useState(false);
  const [goToEndDateFocus, setGoToEndDateFocus] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);

  const classes = baseStyles();

  const getReport = async () => {
    const file = await post({
      endpoint: 'reports/crematory',
      body: JSON.stringify({
        startDate: startDate?.format('Y-M-D') + 'T00:00:00',
        endDate: endDate?.format('Y-M-D') + 'T23:59:59',
        aftercareLocation: aftercareLocation?.id,
      }),
    });
    if (file.ok) {
      await downloadCSV(file, 'crematory-report.csv');
    } else {
      setSuccess(false);
      setSnackbarMessage('There was an error generating the report.');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (aftercareLocation !== null && startDate !== null && endDate !== null) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [aftercareLocation, endDate, startDate]);

  useEffect(() => {
    const getFilteredAftercareLocations = () => {
      get({
        endpoint: `aftercare_locations?deleted=false&name=${aftercareLocationSearchText}`,
      }).then(async response => {
        const aftercareLocationsJson = await response.json();
        setAftercareLocations(aftercareLocationsJson['hydra:member']);
      });
    };

    getFilteredAftercareLocations();
  }, [aftercareLocationSearchText]);

  return (
    <>
      <div className={classes.reportContainer}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Crematory Charges Report
        </Typography>
        <FormControl variant="outlined" style={{ width: '250px' }}>
          <InputLabel className={classes.label}>Aftercare Location</InputLabel>
          <Autocomplete
            id="crematory-select-modal"
            options={aftercareLocations}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select Location"
                onChange={evt =>
                  setAftercareLocationSearchText(evt.target.value as string)
                }
              />
            )}
            getOptionLabel={option => option.name.toString()}
            onChange={(evt, value) => setAftercareLocation(value)}
            value={aftercareLocation}
          />
        </FormControl>
        <InputLabel className={classes.label}>Date Range</InputLabel>
        <SingleDatePicker
          date={startDate}
          onDateChange={date => setStartDate(date)}
          focused={goToStartDateFocus}
          onFocusChange={({ focused }) => setGoToStartDateFocus(focused)}
          displayFormat="MMM-DD-YYYY"
          id={'goToDate'}
          numberOfMonths={1}
          isOutsideRange={() => false}
          placeholder="Start Date"
        />
        <SingleDatePicker
          date={endDate}
          onDateChange={date => setEndDate(date)}
          focused={goToEndDateFocus}
          onFocusChange={({ focused }) => setGoToEndDateFocus(focused)}
          displayFormat="MMM-DD-YYYY"
          id={'goToDate'}
          numberOfMonths={1}
          isOutsideRange={() => false}
          placeholder="End Date"
        />
        <div>
          <Button
            disabled={!buttonEnabled}
            variant="contained"
            className={classes.button}
            onClick={getReport}
          >
            Generate Report
          </Button>
        </div>
      </div>
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </>
  );
};

export const CrematoryReport: FC = () => (
  <ReportsProvider>
    <Report />
  </ReportsProvider>
);
