export const appointmentTypesMap = [
  { id: 'DutyEvent', name: 'Duty' },
  { id: 'PlaceholderAppointmentEvent', name: 'Hold' },
  { id: 'BreakEvent', name: 'Break' },
  { id: 'AftercareAppointmentEvent', name: 'Aftercare' },
  { id: 'CacAppointmentEvent', name: 'CAC' },
  { id: 'EuthanasiaAppointmentEvent', name: 'Euthanasia' },
];

export const getAppointmentTypeById = (id: string): string => {
  const appt = appointmentTypesMap.find(_type => _type.id === id);
  return appt === undefined ? '' : appt.name;
};
