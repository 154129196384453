import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Runnable, Consumer, Pet } from './types';
import { patch, post } from '../../utils/Api';
import { ModalDialog } from './ModalDialog';
import { PetSelectBundle } from './PetSelectBundle';

interface Props {
  callback: Consumer<{ success: boolean; message: string; id?: string }>;
  closeCallback: Runnable;
  hidePlus?: boolean;
  ownerId?: string;
}

export const PetFormModal: React.FC<Props> = ({
  callback,
  closeCallback,
  hidePlus,
  ownerId,
}) => {
  const [pet, setPet] = useState<Pet | null>(null);
  const [disabled, setDisabled] = useState(true);
  const savePet = async () => {
    // Disable button after click to prevent duplicated actions
    setDisabled(true);
    if (pet?.id === undefined || pet.id === '') {
      const petBody = JSON.stringify({
        name: pet?.name,
        lastname: pet?.lastname,
        gender: pet?.gender,
        age: pet?.age,
        weight: pet?.weight,
        deceased: false,
        breed: pet?.breed ? `/breeds/${pet?.breed?.id}` : null,
        species: pet?.species ? `/species/${pet?.species?.id}` : null,
        veterinarians: [],
        referral: `${pet?.referral}`,
        owners: ownerId ? [`/owners/${ownerId}`] : [],
        petNotes: [],
      });
      const petRes = await post({
        endpoint: 'pets',
        body: petBody,
      });
      const jsonBody = await petRes.json();
      if (petRes.ok) {
        callback({
          success: true,
          message: 'Pet created successfully.',
          id: jsonBody['@id'],
        });
      } else {
        callback({
          success: false,
          message: 'There was an error creating the pet.',
        });
      }
    } else {
      if (ownerId) {
        const petBody = JSON.stringify({
          owners: [...pet.owners, `/owners/${ownerId}`],
        });
        const petRes = await patch({
          endpoint: `pets/${pet.id}`,
          body: petBody,
        });
        const jsonBody = await petRes.json();
        if (petRes.ok) {
          callback({
            success: true,
            message: 'Pet added successfully.',
            id: jsonBody['@id'],
          });
        } else {
          callback({
            success: false,
            message: 'There was an error adding the pet.',
          });
        }
      }
    }
  };
  useEffect(() => {
    if (pet !== null) {
      if (pet.name.trim() !== '') {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [pet]);
  return (
    <ModalDialog
      title={'Add Pet'}
      closeCallback={closeCallback}
      headerColor={'#CFCFCF'}
      buttonText={'Add Pet'}
      actionCallback={savePet}
      buttonEnabled={disabled}
    >
      <PetSelectBundle
        selectedPet={pet}
        onChangeCallback={setPet}
        hidePlus={hidePlus}
        selectedClient={ownerId}
      />
    </ModalDialog>
  );
};
