import React, { useEffect, useState } from 'react';
import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';
import { Client, Runnable, Consumer } from './types';
import { patch, post } from '../../utils/Api';
import { ModalDialog } from './ModalDialog';
import { ClientSelectBundle } from './ClientSelectBundle';
import { validateEmail } from '../../utils/StringUtils';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '413px',
      margin: 0,
      padding: 0,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface Props {
  callback: Consumer<{ success: boolean; message: string; id?: string }>;
  closeCallback: Runnable;
  hidePlus?: boolean;
  petId?: string;
}

export const ClientFormModal: React.FC<Props> = ({
  callback,
  closeCallback,
  hidePlus,
  petId,
}) => {
  const [client, setClient] = useState<Client | null>(null);
  const [disabled, setDisabled] = useState(true);

  const saveClient = async () => {
    if (client?.id === undefined || client.id === '') {
      // Disable button after click to prevent duplicated actions
      setDisabled(true);
      const ownerBody = JSON.stringify({
        firstname: client?.firstname,
        lastname: client?.lastname,
        telephone: client?.telephone,
        alternateTelephone: client?.alternateTelephone,
        email: client?.email,
        primary: false,
        addressLine1: client?.addressLine1,
        location: client?.location,
        state: client?.state,
        city: client?.city,
        pets: petId ? [`/pets/${petId}`] : [],
        postalCode: client?.postalCode,
      });
      const ownerRes = await post({
        endpoint: 'owners',
        body: ownerBody,
      });
      const jsonBody = await ownerRes.json();
      if (ownerRes.ok) {
        callback({
          success: true,
          message: 'Client created successfully.',
          id: jsonBody['@id'],
        });
      } else {
        callback({
          success: false,
          message: 'There was an error creating the client.',
        });
      }
    } else {
      if (petId) {
        const ownerBody = JSON.stringify({
          pets: [
            ...client.pets.map(_pet => `/pets/${_pet.id}`),
            `/pets/${petId}`,
          ],
        });
        const ownerRes = await patch({
          endpoint: `owners/${client.id}`,
          body: ownerBody,
        });
        const jsonBody = await ownerRes.json();
        if (ownerRes.ok) {
          callback({
            success: true,
            message: 'Client added successfully.',
            id: jsonBody['@id'],
          });
        } else {
          callback({
            success: false,
            message: 'There was an error adding the client.',
          });
        }
      }
    }
  };
  useEffect(() => {
    if (client !== null) {
      if (client.id === '') {
        if (client.lastname.trim() !== '') {
          setDisabled(false);
          if (client.email.trim() !== '') {
            if (validateEmail(client.email.trim())) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          } else {
            setDisabled(false);
          }
        } else {
          setDisabled(true);
        }
      } else {
        setDisabled(false);
      }
    }
  }, [client]);
  return (
    <ModalDialog
      title={'Add Client'}
      closeCallback={closeCallback}
      headerColor={'#CFCFCF'}
      buttonText={'Add Client'}
      actionCallback={saveClient}
      buttonEnabled={disabled}
    >
      <ClientSelectBundle
        selectedClient={client}
        onChangeCallback={setClient}
        hidePlus={hidePlus}
      />
    </ModalDialog>
  );
};
