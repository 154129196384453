import React, { FC } from 'react';
import {
  Edit,
  TextInput,
  EditProps,
  SimpleForm,
  required,
  regex,
  BooleanInput,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const AftercareLocationEdit: FC<EditProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Edit {...props} resource="aftercare_locations">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Code should not be or start with a space.'),
          ]}
          source="code"
        />
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Name should not be or start with a space.'),
          ]}
          source="name"
        />
        <BooleanInput source="cac" label="CAC enabled" />
        <BooleanInput source="deleted" />
      </SimpleForm>
    </Edit>
  </RolePageProvider>
);
