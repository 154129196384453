import React, { FC } from 'react';
import {
  Show,
  TextField,
  ShowProps,
  SimpleShowLayout,
  BooleanField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ColorField } from 'react-admin-color-input';

export const ColorCodeShow: FC<ShowProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <ColorField source="code" />
        <BooleanField source="deleted" />
      </SimpleShowLayout>
    </Show>
  </RolePageProvider>
);
