import React, { FC } from 'react';
import {
  Create,
  TextInput,
  CreateProps,
  SimpleForm,
  required,
  regex,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const DiagnosisCreate: FC<CreateProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Create {...props} resource="diagnoses">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(
              /^(?!\s)/,
              'Description should not be or start with a space.',
            ),
          ]}
          source="description"
        />
      </SimpleForm>
    </Create>
  </RolePageProvider>
);
