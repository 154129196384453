import React, { FC } from 'react';
import {
  required,
  Create,
  TextInput,
  CreateProps,
  BooleanInput,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
import { SpeciesInput } from './SpeciesInput';

export const PetCreate: FC<CreateProps> = props => {
  return (
    <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
      <Create {...props} basePath="/pets" resource="pets">
        <SimpleForm redirect="/pets">
          <ReferenceInput
            label="Household"
            source="household"
            reference="households"
          >
            <SelectInput validate={[required()]} optionText="displayName" />
          </ReferenceInput>
          <TextInput validate={[required()]} source="name" />
          <NumberInput source="age" />
          <SelectInput
            source="gender"
            choices={[
              { id: 'Male', name: 'Male' },
              { id: 'Female', name: 'Female' },
            ]}
          />
          <SpeciesInput source="name" />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceInput
                label="Breed"
                source="breed"
                reference="breeds"
                disabled={!formData.species}
                filter={{ species: formData.species }}
                {...rest}
              >
                <SelectInput validate={[required()]} source="name" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <NumberInput source="weight" />
          <ReferenceInput
            label="Diagnosis"
            source="diagnosis"
            reference="diagnoses"
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <BooleanInput source="deceased" />
          <ReferenceInput
            label="Veterinarian"
            source="veterinarian"
            reference="veterinarians"
          >
            <SelectInput optionText="practiceName" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </RolePageProvider>
  );
};
