import React, { useEffect, useState } from 'react';
import { TabbedShowLayout, Tab } from 'react-admin';
import { useHistory } from 'react-router-dom';
import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import 'react-datepicker/dist/react-datepicker.css';
import { Runnable, Event, Consumer } from './types';
import { ModalDialog } from './ModalDialog';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import { getAppointmentTypeById } from '../../utils/AppointmentTypes';
import { deleteEvent } from '../../utils/AppointmentUtils';
import { AlertDialog } from './AlertDialog';
import { RoleLinkProvider } from './RoleLinkProvider';
import Divider from '@material-ui/core/Divider';
import {
  getPetMemorialOption,
  getTotalAftercareCharge,
} from '../../utils/MemorialOptionUtils';
import { Moment } from 'moment';
import { post } from '../../utils/Api';
import { downloadDoc } from '../../utils/StringUtils';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '413px',
      margin: 0,
      padding: 0,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface Props {
  closeCallback: Runnable;
  callback: Consumer<{ success: boolean; message: string }>;
  event: Event;
  editCallback: Runnable;
  showActionLinks: boolean;
}
const modalStyles = makeStyles({
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  topLink: {
    padding: '0 10px',
    color: '#000',
    lineHeight: '14px',
    '&:not(:first-child)': {
      borderLeft: '1px solid',
    },
  },
  twoDivided: {
    display: 'flex',
    width: '100%',
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '40px',
    },
  },
  addOns: {
    display: 'flex',
    width: '50%',
  },
  addOnItem: {
    marginRight: '15px',
    alignSelf: 'center',
  },
  addOnItemLabel: {
    fontSize: '10px',
  },
  dividedField: {
    width: '158px',
    marginRight: '16px',
  },
  left: {
    textAlign: 'left',
    width: '50%',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '15px !important',
  },
  text: {
    fontSize: '15px !important',
  },
  block: {
    marginTop: '20px',
  },
  full: {
    width: '100%',
  },
  textLeft: {
    width: '60%',
  },
  textRight: {
    width: '40%',
    textAlign: 'right',
  },
  underline: {
    textDecoration: 'underline',
  },
  petNote: {
    fontSize: '13px',
    margin: '10px 0',
  },
  petNotesBlock: {
    marginBottom: '30px',
  },
  bold: {
    fontWeight: 700,
  },
  divider: {
    backgroundColor: '#000',
    marginBottom: '40px',
    marginTop: '30px',
  },
});
export const AppointmentDetailsModal: React.FC<Props> = ({
  closeCallback,
  event,
  callback,
  editCallback,
  showActionLinks,
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [date, setDate] = useState<Moment | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const classes = modalStyles();
  const history = useHistory();
  const getReferralLetter = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const _vet of event.pet.veterinarians) {
      const file = await post({
        endpoint: 'reports/referral-letter',
        body: JSON.stringify({
          petId: event.pet?.id,
          vetId: _vet.id,
        }),
      });
      if (file.ok) {
        if (file.status === 205) {
          callback({
            success: false,
            message:
              'The selected pet should have at least an appointment to generate a referral letter.',
          });
        } else {
          await downloadDoc(file, 'referral-letter.docx');
        }
      } else {
        callback({
          success: false,
          message: 'There was an error generating the referral letter.',
        });
      }
    }
  };

  const deleteAppointment = () => {
    deleteEvent(event).then(_res => {
      if (!_res.ok) {
        callback({
          success: false,
          message: 'There was an error while deleting the event.',
        });
      } else {
        callback({
          success: true,
          message: 'Event deleted successfully.',
        });
      }
    });
  };

  useEffect(() => {
    const dts = new Date(event.startTime).getTime();

    setDate(moment(dts));
    setStartTime(new Date(event.startTime));
    setEndTime(new Date(event.endTime));
  }, [event]);

  const printAddOnsDetails = () => {
    return (
      <>
        <Typography
          variant="body1"
          className={`${classes.addOnItem} ${classes.addOnItemLabel}`}
        >
          CP
        </Typography>
        <Typography variant="body1" className={classes.addOnItem}>
          {
            getPetMemorialOption(
              event.pet ? event.pet?.memorialOptions : undefined,
              'CP',
            ).amount
          }
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.addOnItem} ${classes.addOnItemLabel}`}
        >
          IP
        </Typography>
        <Typography variant="body1" className={classes.addOnItem}>
          {
            getPetMemorialOption(
              event.pet ? event.pet?.memorialOptions : undefined,
              'IP',
            ).amount
          }
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.addOnItem} ${classes.addOnItemLabel}`}
        >
          NP
        </Typography>
        <Typography variant="body1" className={classes.addOnItem}>
          {
            getPetMemorialOption(
              event.pet ? event.pet?.memorialOptions : undefined,
              'NP',
            ).amount
          }
        </Typography>
      </>
    );
  };
  return (
    <ModalDialog
      title={'Appointment Details'}
      closeCallback={closeCallback}
      headerColor={'#CFCFCF'}
      buttonText={
        getAppointmentTypeById(event['@type']) === 'Euthanasia' ||
        getAppointmentTypeById(event['@type']) === 'Aftercare'
          ? 'View Medical Record'
          : null
      }
      actionCallback={() =>
        history.push(`/pets/%2Fpets%2F${event.pet?.id}/show`)
      }
    >
      <TabbedShowLayout>
        <Tab label={'Details'}>
          {showActionLinks && (
            <RoleLinkProvider
              allowed={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_STAFF']}
            >
              <div className={classes.buttons}>
                {(getAppointmentTypeById(event['@type']) === 'Euthanasia' ||
                  getAppointmentTypeById(event['@type']) === 'CAC' ||
                  getAppointmentTypeById(event['@type']) === 'Aftercare') && (
                  <Link
                    component="button"
                    className={classes.topLink}
                    variant="body2"
                    onClick={getReferralLetter}
                  >
                    Referral Letter
                  </Link>
                )}
                <Link
                  component="button"
                  className={classes.topLink}
                  variant="body2"
                  onClick={editCallback}
                >
                  Edit
                </Link>
                <Link
                  component="button"
                  className={classes.topLink}
                  variant="body2"
                  onClick={() => setAlertOpen(true)}
                >
                  Delete
                </Link>
              </div>
            </RoleLinkProvider>
          )}
          <div className={classes.block}>
            <div className={classes.twoDivided}>
              <Typography
                variant="body1"
                className={`${classes.left} ${classes.label}`}
              >
                Date
              </Typography>
              <Typography
                variant="body1"
                className={`${classes.left} ${classes.label}`}
              >
                Time
              </Typography>
            </div>
            <div className={classes.twoDivided}>
              <Typography
                variant="body1"
                className={`${classes.left} ${classes.text}`}
              >
                {date ? date.format('MM/DD/YYYY') : ''}
              </Typography>
              <Typography
                variant="body1"
                className={`${classes.left} ${classes.text}`}
              >
                {moment(startTime, 'local').format('h:mm A') +
                  ' - ' +
                  moment(endTime, 'local').format('h:mm A')}
              </Typography>
            </div>
          </div>
          <div className={classes.block}>
            <div className={classes.twoDivided}>
              <Typography
                variant="body1"
                className={`${classes.left} ${classes.label}`}
              >
                Topic
              </Typography>
            </div>
            <div className={classes.twoDivided}>
              <Typography
                variant="body1"
                className={`${classes.left} ${classes.text}`}
              >
                {event.description ?? '-'}
              </Typography>
            </div>
          </div>
          <div className={classes.block}>
            <div className={classes.twoDivided}>
              <Typography
                variant="body1"
                className={`${classes.left} ${classes.label}`}
              >
                Appointment Type
              </Typography>
            </div>
            <div className={classes.twoDivided}>
              <Typography
                variant="body1"
                className={`${classes.left} ${classes.text}`}
              >
                {getAppointmentTypeById(event['@type'])}
              </Typography>
            </div>
          </div>
          {(getAppointmentTypeById(event['@type']) === 'Euthanasia' ||
            getAppointmentTypeById(event['@type']) === 'CAC' ||
            getAppointmentTypeById(event['@type']) === 'Aftercare') && (
            <>
              <div className={classes.block}>
                <div>
                  <Typography
                    onClick={() =>
                      history.push(
                        `/owners/%2Fowners%2F${
                          event.pet?.primaryOwner === null ||
                          event.pet?.primaryOwner === undefined
                            ? event.owner?.id
                            : event.pet?.primaryOwner?.id
                        }/show`,
                      )
                    }
                    variant="body1"
                    className={`${classes.left} ${classes.label} ${classes.full} ${classes.underline}`}
                  >
                    {`${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.firstname
                        : event.pet?.primaryOwner?.firstname
                    } ${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.lastname
                        : event.pet?.primaryOwner?.lastname
                    }`}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {event.pet?.primaryOwner === null ||
                    event.pet?.primaryOwner === undefined
                      ? event.owner?.telephone
                      : event.pet?.primaryOwner?.telephone}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {event.pet?.primaryOwner === null ||
                    event.pet?.primaryOwner === undefined
                      ? event.owner?.email
                      : event.pet?.primaryOwner?.email}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {`${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.addressLine1
                        : event.pet?.primaryOwner?.addressLine1
                    }`}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {`${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.city
                        : event.pet?.primaryOwner?.city
                    } ${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.state
                        : event.pet?.primaryOwner?.state
                    } ${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.postalCode
                        : event.pet?.primaryOwner?.postalCode
                    }`}
                  </Typography>
                  <Link
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    href={`https://maps.google.com/?q=${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.addressLine1
                        : event.pet?.primaryOwner?.addressLine1
                    }, ${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.city
                        : event.pet?.primaryOwner?.city
                    } ${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.state
                        : event.pet?.primaryOwner?.state
                    } ${
                      event.pet?.primaryOwner === null ||
                      event.pet?.primaryOwner === undefined
                        ? event.owner?.postalCode
                        : event.pet?.primaryOwner?.postalCode
                    }`}
                  >
                    Map Link
                  </Link>
                  {event.pet?.secondaryOwner !== null &&
                    event.pet?.secondaryOwner !== undefined &&
                    event.pet?.secondaryOwner !== event.pet?.primaryOwner && (
                      <div className={classes.block}>
                        <div>
                          <Typography
                            onClick={() =>
                              history.push(
                                `/owners/%2Fowners%2F${event.pet?.secondaryOwner?.id}/show`,
                              )
                            }
                            variant="body1"
                            className={`${classes.left} ${classes.label} ${classes.full} ${classes.underline}`}
                          >
                            {`${event.pet?.secondaryOwner?.firstname} ${event.pet?.secondaryOwner?.lastname}`}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body1"
                            className={`${classes.left} ${classes.full} ${classes.text}`}
                          >
                            {event.pet?.secondaryOwner?.telephone}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={`${classes.left} ${classes.full} ${classes.text}`}
                          >
                            {event.pet?.secondaryOwner?.email}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={`${classes.left} ${classes.full} ${classes.text}`}
                          >
                            {`${event.pet?.secondaryOwner?.addressLine1} ${event.pet?.secondaryOwner?.location}`}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={`${classes.left} ${classes.full} ${classes.text}`}
                          >
                            {`${event.pet?.secondaryOwner?.city} ${event.pet?.secondaryOwner?.state} ${event.pet?.secondaryOwner?.postalCode}`}
                          </Typography>
                          <Link
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            href={`https://maps.google.com/?q=${event.pet?.secondaryOwner?.addressLine1} ${event.pet?.secondaryOwner?.location}, ${event.pet?.secondaryOwner?.city} ${event.pet?.secondaryOwner?.state} ${event.pet?.secondaryOwner?.postalCode}`}
                          >
                            Map Link
                          </Link>
                        </div>
                      </div>
                    )}
                  <div className={`${classes.block} ${classes.petNotesBlock}`}>
                    <div>
                      <Typography
                        variant="body1"
                        className={`${classes.left} ${classes.label} ${classes.full}`}
                      >
                        Client Latest Note
                      </Typography>
                    </div>
                    {event.owner?.ownerNotes?.slice(0, 1).map(_note => (
                      <div key={_note.id}>
                        <Typography
                          variant="body1"
                          className={`${classes.left} ${classes.label} ${classes.full} ${classes.petNote}`}
                        >
                          {moment(_note.noteDateTime).format(
                            'MM/DD/YYYY, h:mm A',
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={`${classes.left} ${classes.full} ${classes.petNote}`}
                        >
                          <span className={classes.bold}>
                            {`${
                              _note.addedBy === '' ? 'Admin' : _note.addedBy
                            }`}
                            :{' '}
                          </span>
                          <label
                            dangerouslySetInnerHTML={{
                              __html: _note.note ?? '',
                            }}
                          />
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={classes.block}>
                <div>
                  <Typography
                    onClick={() =>
                      history.push(`/pets/%2Fpets%2F${event.pet?.id}/show`)
                    }
                    variant="body1"
                    className={`${classes.left} ${classes.label} ${classes.full} ${classes.underline}`}
                  >
                    {`Re: ${event.pet?.name}`}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {event.pet?.species
                      ? event.pet?.species.name
                      : event.pet?.breed?.species.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {event.pet?.breed ? event.pet?.breed.name : ''}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {event.pet?.gender}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {`Age ${event.pet?.age}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.full} ${classes.text}`}
                  >
                    {`${event.pet?.weight} lbs`}
                  </Typography>
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label}`}
                  >
                    {getAppointmentTypeById(event['@type']) === 'CAC'
                      ? 'CAC Clinic'
                      : 'Veterinarians'}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label}`}
                  >
                    CC Doctor
                  </Typography>
                </div>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.pet &&
                    event.pet.veterinarians &&
                    event.pet.veterinarians.length > 0
                      ? event.pet?.veterinarians
                          .map(_vet => _vet.practiceName)
                          .join(', ')
                      : '-'}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.user?.name ?? '-'}
                  </Typography>
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label}`}
                  >
                    Aftercare
                  </Typography>
                  {getAppointmentTypeById(event['@type']) !== 'Euthanasia' && (
                    <Typography
                      variant="body1"
                      className={`${classes.left} ${classes.label}`}
                    >
                      Aftercare Location
                    </Typography>
                  )}
                </div>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.aftercare?.code ?? '-'}
                  </Typography>
                  {getAppointmentTypeById(event['@type']) !== 'Euthanasia' && (
                    <Typography
                      variant="body1"
                      className={`${classes.left} ${classes.text}`}
                    >
                      {event.aftercareLocation?.name ?? '-'}
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label}`}
                  >
                    Consultation Charge
                  </Typography>
                  {getAppointmentTypeById(event['@type']) === 'Euthanasia' && (
                    <Typography
                      variant="body1"
                      className={`${classes.left} ${classes.label}`}
                    >
                      Euthanasia Charge
                    </Typography>
                  )}
                </div>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.consultationCharge ?? '-'}
                  </Typography>
                  {getAppointmentTypeById(event['@type']) === 'Euthanasia' && (
                    <Typography
                      variant="body1"
                      className={`${classes.left} ${classes.text}`}
                    >
                      {event.euthanasiaCharge ?? '-'}
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label}`}
                  >
                    Aftercare Charge
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label}`}
                  >
                    Add-ons (Quantity)
                  </Typography>
                </div>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.aftercareCharge ?? '-'}
                  </Typography>
                  <div className={classes.addOns}>{printAddOnsDetails()}</div>
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label}`}
                  >
                    Referral
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label}`}
                  >
                    Diagnosis
                  </Typography>
                </div>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.pet?.referral}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.pet?.diagnosis?.description}
                  </Typography>
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label} ${classes.full}`}
                  >
                    Contact Preference
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label} ${classes.full}`}
                  >
                    Transport Only
                  </Typography>
                </div>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.contactPreference}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.transport ? 'Y' : 'N'}
                  </Typography>
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label} ${classes.full}`}
                  >
                    Late Cancellation
                  </Typography>
                </div>
                <div className={classes.twoDivided}>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.text}`}
                  >
                    {event.lateCancelation ? 'Y' : 'N'}
                  </Typography>
                </div>
              </div>
              <div className={`${classes.block} ${classes.petNotesBlock}`}>
                <div>
                  <Typography
                    variant="body1"
                    className={`${classes.left} ${classes.label} ${classes.full}`}
                  >
                    Pet Notes
                  </Typography>
                </div>
                {event.pet?.petNotes
                  ?.sort((a, b) => {
                    const aDate = new Date(a.noteDateTime);
                    const bDate = new Date(b.noteDateTime);
                    return aDate.getTime() - bDate.getTime();
                  })
                  .map(_note => (
                    <div key={_note.id}>
                      <Typography
                        variant="body1"
                        className={`${classes.left} ${classes.label} ${classes.full} ${classes.petNote}`}
                      >
                        {moment(_note.noteDateTime).format(
                          'MM/DD/YYYY, h:mm A',
                        )}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`${classes.left} ${classes.full} ${classes.petNote}`}
                      >
                        <span className={classes.bold}>
                          {`${_note.addedBy === '' ? 'Admin' : _note.addedBy}`}:{' '}
                        </span>
                        <label
                          dangerouslySetInnerHTML={{ __html: _note.note ?? '' }}
                        />
                      </Typography>
                    </div>
                  ))}
              </div>
            </>
          )}
        </Tab>
        <Tab
          label={'Charges'}
          disabled={
            getAppointmentTypeById(event['@type']) === 'Hold' ||
            getAppointmentTypeById(event['@type']) === 'Break' ||
            getAppointmentTypeById(event['@type']) === 'Duty' ||
            localStorage.getItem('role') === 'ROLE_VETERINARIAN'
          }
        >
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Consultation Charge
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${event.consultationCharge}
            </Typography>
          </div>
          {getAppointmentTypeById(event['@type']) === 'Euthanasia' && (
            <div className={classes.twoDivided}>
              <Typography variant="body1" className={classes.textLeft}>
                Euthanasia Charge
              </Typography>
              <Typography variant="body1" className={classes.textRight}>
                ${event.euthanasiaCharge}
              </Typography>
            </div>
          )}

          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Crematory Facility
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              {event.aftercareLocation?.name}
            </Typography>
          </div>
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Aftercare Base Charge
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${event.aftercareCharge}
            </Typography>
          </div>
          <Typography variant="body1">Add-ons(Quantity)</Typography>
          <div className={classes.twoDivided}>{printAddOnsDetails()}</div>
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Total Aftercare Charge
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${getTotalAftercareCharge(event)}
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Doctor Payment
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${event.doctorPayment}
            </Typography>
          </div>
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Crematory Payment
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${event.crematoryPayment}
            </Typography>
          </div>
        </Tab>
      </TabbedShowLayout>
      <AlertDialog
        message={'Are you sure you want to delete this event?'}
        title={'Delete event?'}
        open={alertOpen}
        accept={deleteAppointment}
        cancel={() => setAlertOpen(false)}
      />
    </ModalDialog>
  );
};
