import React, { FC } from 'react';
import {
  List,
  TextField,
  ListProps,
  Datagrid,
  EditButton,
  BooleanField,
  ShowButton,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const VeterinarianList: FC<ListProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <List {...props} hasEdit={true} hasShow={true} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="practiceName" />
        <TextField source="email" />
        <TextField source="contactFirstName" />
        <TextField source="contactLastName" />
        <BooleanField source="deleted" sortable={false} />
        <BooleanField source="cac" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  </RolePageProvider>
);
