import React, { FC } from 'react';
import {
  Create,
  TextInput,
  CreateProps,
  SimpleForm,
  SelectInput,
  NumberInput,
  ReferenceInput,
  required,
  email,
  regex,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const DoctorCreate: FC<CreateProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Create {...props}>
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Name should not be or start with a space.'),
          ]}
          source="name"
        />

        <TextInput
          validate={[required(), email()]}
          source="email"
          type="email"
        />
        <TextInput source="telephone" label="Phone" />
        <TextInput source="initials" label="Initials" />
        <TextInput
          validate={[required()]}
          source="newPassword"
          type="password"
        />
        <NumberInput source={'perDiemAllowance'} label="Per Diem Allowance" />
        <NumberInput source={'raise'} label="Raise %" />
        <ReferenceInput
          label="Color Code"
          source="colorCode"
          reference="color_codes"
          filterToQuery={() => ({ deleted: false, itemsPerPage: 1000 })}
        >
          <SelectInput validate={[required()]} optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  </RolePageProvider>
);
