import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LookingGlassSVG: FC = () => (
  <SvgIcon
    style={{ height: 17, width: 17 }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
  >
    <path d="M.8 17c-.2 0-.3 0-.5-.1-.1-.1-.2-.2-.3-.4V16c0-.2.1-.3.2-.4l3.3-3.3c1-1 2.5-1.2 3.7-.5 1.1.7 2.5.9 3.8.6 1.3-.3 2.4-1 3.2-2 .8-1 1.2-2.3 1.1-3.7s-.6-2.6-1.6-3.5c-2.1-2.1-5.5-2.1-7.7 0-.6.7-1.1 1.6-1.4 2.5-.2 1-.2 2 .1 2.9.1.2 0 .4-.1.6-.1.3-.3.4-.5.5-.2.1-.4 0-.6-.1-.2-.1-.3-.3-.4-.5-.3-1-.4-2.2-.2-3.2.2-1.1.6-2.1 1.3-3S5.7 1.3 6.7.8 8.8 0 9.9 0c1.4 0 2.8.4 3.9 1.2 1.2.8 2.1 1.9 2.7 3.2.5 1.3.7 2.7.4 4.1-.3 1.4-1 2.6-1.9 3.6-2.3 2.3-5.8 2.7-8.6 1.1-.3-.1-.6-.2-.9-.2-.3 0-.6.2-.8.4l-3.3 3.3c-.1.1-.2.1-.3.2-.1.1-.2.1-.3.1z" />
    <path d="M7.2 6.6c-.1 0-.3 0-.4-.1-.1 0-.2-.1-.3-.2s-.1-.2-.1-.4v-.4c.3-1 1.1-1.8 2-2.2.9-.4 2-.5 3-.2.1 0 .2.1.3.2.1.1.1.2.2.3 0 .1.1.2.1.3v.3c0 .1-.1.2-.2.3-.1.1-.2.1-.3.2-.1 0-.2.1-.3.1h-.3c-.6-.2-1.2-.2-1.7.1-.6.2-1 .6-1.2 1.2-.1.2-.2.3-.3.4s-.3.2-.5.1z" />
  </SvgIcon>
);

export default LookingGlassSVG;
