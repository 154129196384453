import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Runnable } from './types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = makeStyles({
  success: {
    backgroundColor: '#FFF',
    color: '#000',
    width: '376px',
    height: '54px',
    fontWeight: 400,
    '& .MuiAlert-icon': {
      color: '#3D931F',
      alignItems: 'center',
    },
    '& .MuiAlert-message': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  error: {
    backgroundColor: '#FFF',
    color: '#000',
    width: '376px',
    fontWeight: 400,
    '& .MuiAlert-icon': {
      color: '#CE3333',
      alignItems: 'center',
    },
    '& .MuiAlert-message': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  message: {
    width: '300px',
    display: 'inline-block',
  },
  iconButton: {
    marginLeft: '25px',
    color: '#6A6E70',
  },
});
interface AlertProps {
  message: string;
  close: Runnable;
}
const SuccessAlert: FC<AlertProps> = ({ message, close }) => {
  const classes = styles();
  return (
    <MuiAlert
      iconMapping={{
        error: <CheckCircleOutlineIcon fontSize="inherit" color={'error'} />,
      }}
      elevation={6}
      variant="filled"
      className={classes.success}
    >
      <span className={classes.message}>{message}</span>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={close}
        className={classes.iconButton}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </MuiAlert>
  );
};

const ErrorAlert: FC<AlertProps> = ({ message, close }) => {
  const classes = styles();
  return (
    <MuiAlert
      iconMapping={{ success: <WarningIcon fontSize="inherit" /> }}
      elevation={6}
      variant="filled"
      className={classes.error}
    >
      <span className={classes.message}>{message}</span>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={close}
        className={classes.iconButton}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </MuiAlert>
  );
};

interface Props {
  success: boolean | null;
  message: string;
  open: boolean;
  close: Runnable;
}

export const Notification: FC<Props> = ({ success, message, open, close }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={close}>
      {success ? (
        <SuccessAlert message={message} close={close} />
      ) : (
        <ErrorAlert message={message} close={close} />
      )}
    </Snackbar>
  );
};
