import React, { FC } from 'react';
import {
  Create,
  TextInput,
  CreateProps,
  SimpleForm,
  regex,
  BooleanInput,
  email,
  RadioButtonGroupInput,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const VeterinarianCreate: FC<CreateProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Create {...props} resource="veterinarians">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Practice Name should not be or start with a space.',
            ),
          ]}
          source="practiceName"
        />
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Practice Contact Number should not be or start with a space.',
            ),
          ]}
          source="practiceContactNumber"
        />
        <RadioButtonGroupInput
          source="practiceContactNumberType"
          choices={[
            { id: 0, name: 'Fax Number' },
            { id: 1, name: 'Contact Number' },
          ]}
        />
        <TextInput
          validate={[
            email(),
            regex(/^(?!\s)/, 'Email should not be or start with a space.'),
          ]}
          source="email"
          label="Contact Email Address"
        />
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Street Line should not be or start with a space.',
            ),
          ]}
          source="streetLine1"
        />
        <TextInput source="streetLine2" />
        <TextInput
          validate={[
            regex(/^(?!\s)/, 'City should not be or start with a space.'),
          ]}
          source="city"
        />
        <TextInput
          validate={[
            regex(/^(?!\s)/, 'State should not be or start with a space.'),
          ]}
          source="state"
        />
        <TextInput
          validate={[
            regex(/^(?!\s)/, 'Zip should not be or start with a space.'),
          ]}
          source="zip"
        />
        <TextInput
          validate={[
            regex(/^(?!\s)/, 'Phone should not be or start with a space.'),
          ]}
          source="phone"
        />
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Contact Name should not be or start with a space.',
            ),
          ]}
          source="contactFirstName"
        />
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Contact Last name should not be or start with a space.',
            ),
          ]}
          source="contactLastName"
        />
        <BooleanInput source="cac" />
      </SimpleForm>
    </Create>
  </RolePageProvider>
);
