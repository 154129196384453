import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { SingleDatePicker } from 'react-dates';
import { Moment } from 'moment';
import { Consumer, Runnable } from '../shared/types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 0,
      marginBottom: '26px',
      fontFamily: 'Mulish',
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        marginRight: '15px',
      },
      '& .DateInput_input__focused': {
        border: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        maxWidth: '74px',
      },
      '& .DateInput': {
        width: 'auto',
      },
      '& .DateInput_input': {
        maxWidth: '74px',
        border: 0,
        padding: '5px',
        fontSize: '13px',
        lineHeight: '18px',
        [theme.breakpoints.up('sm')]: {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
    button: {
      marginLeft: '64px',
      fontSize: '16px',
      fontWeight: 600,
    },
    span: {
      fontSize: '14px',
      fontWeight: 600,
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: '22px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '15px',
      },
    },
  };
});
interface TopRightActionsProps {
  buttonAction: Runnable;
  goToDateCallback: Consumer<Moment | null>;
}

export const TopRightActions: React.FC<TopRightActionsProps> = props => {
  const [goToDateFocus, setGoToDateFocus] = useState(false);
  const [currentDate, setCurrentDate] = useState<Moment | null>(null);
  const [currentDateJustChanged, setCurrentDateJustChanged] = useState(false);
  const classes = styles();
  const { goToDateCallback } = props;
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  useEffect(() => {
    if (currentDate !== null && currentDateJustChanged) {
      goToDateCallback(currentDate);
      setCurrentDateJustChanged(false);
    }
  }, [currentDate, currentDateJustChanged, goToDateCallback]);
  return (
    <div className={classes.container} id="top-right-actions">
      <span className={classes.span}>Go to Date:</span>
      {/*TODO Z-Index review on Jons work*/}
      <SingleDatePicker
        date={currentDate}
        onDateChange={date => {
          setCurrentDate(date);
          setCurrentDateJustChanged(true);
        }}
        focused={goToDateFocus}
        onFocusChange={({ focused }) => setGoToDateFocus(focused)}
        displayFormat="MMM-DD-YYYY"
        id={'goToDate'}
        numberOfMonths={1}
        dayPickerNavigationInlineStyles={{ zIndex: 1000 }}
        ariaLabel={'Go To Date'}
        isOutsideRange={() => false}
        placeholder={''}
        anchorDirection={matchesMobile ? 'right' : 'left'}
      />
    </div>
  );
};
