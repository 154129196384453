import React, { useState } from 'react';
import { Consumer } from '../shared/types';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from '@material-ui/icons/Clear';
import { Runnable } from '../shared/types';
import LookingGlassSVG from '../shared/LookingGlassSVG';
import { styled } from '@material-ui/core/styles';

const StyledTextfield = styled(TextField)({
  height: '38px',
  '& .MuiOutlinedInput-adornedEnd': {
    // padding: 0,
    padding: '0 20px 0 0',
    borderRadius: '20px',
    // border: '1px solid',
    minWidth: '160px',
  },
  '& fieldset': {
    border: '1px solid #000',
    borderRadius: '30px',
  },
  '& :focus-within': {
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 20px',
    border: 0,
  },
});
interface Props {
  callback: Consumer<string>;
  searchFocusIn?: Runnable;
  searchFocusOut?: Runnable;
  searchBarExpanded?: boolean;
}
export const SearchBox: React.FC<Props> = ({
  callback,
  searchFocusIn,
  searchFocusOut,
  searchBarExpanded,
}) => {
  const [searchText, setSearchText] = useState<string>('');

  return (
    <div id="search-field">
      <FormControl
        variant="outlined"
        style={{
          width: '100%',
          borderRadius: '30px',
          background: 'none',
        }}
      >
        <StyledTextfield
          id={'searchEntries'}
          type="text"
          className={searchBarExpanded ? 'expanded' : ''}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={searchText}
          onChange={e => {
            setSearchText(e.target.value as string);
            callback(e.target.value as string);
          }}
          variant="outlined"
          placeholder={'Search'}
          helperText={false}
          onFocus={() => {
            if (searchFocusIn) {
              searchFocusIn();
            }
          }}
          onBlur={() => {
            if (searchFocusOut) {
              searchFocusOut();
            }
          }}
          InputProps={
            searchBarExpanded
              ? {
                  endAdornment: [
                    // <LookingGlassSVG />,
                    <ClearIcon
                      key="clear-icon-search-box"
                      onClick={() => {
                        if (searchFocusOut) {
                          searchFocusOut();
                        }
                      }}
                    />,
                  ],
                }
              : {
                  endAdornment: (
                    <LookingGlassSVG key="looking-glass-icon-search-box" />
                  ),
                }
          }
        />
      </FormControl>
    </div>
  );
};
