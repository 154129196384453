import React, { FC, Fragment } from 'react';
import { ReferenceInput, SelectInput, InputProps, required } from 'react-admin';
import { useForm } from 'react-final-form';

export const SpeciesInput: FC<InputProps> = props => {
  const form = useForm();
  return (
    <Fragment {...props}>
      <ReferenceInput
        {...props}
        onChange={() => form.change('breed', null)}
        label="Species"
        source="species"
        reference="species"
      >
        <SelectInput validate={required()} source="name" />
      </ReferenceInput>
    </Fragment>
  );
};
