import React, { FC } from 'react';
import {
  Show,
  TextField,
  ShowProps,
  SimpleShowLayout,
  NumberField,
  ReferenceField,
  BooleanField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ColorField } from 'react-admin-color-input';

export const DoctorShow: FC<ShowProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" addLabel={true} />
        <TextField source="email" addLabel={true} />
        <TextField source="telephone" label="Phone" />
        <TextField source="initials" label="Initials" />
        <NumberField source="perDiemAllowance" addLabel={true} />
        <NumberField source="raise" addLabel={true} />
        <ReferenceField
          source="colorCode"
          reference="color_codes"
          label="Color Code"
        >
          <ColorField source="code" />
        </ReferenceField>
        <BooleanField source="deleted" />
      </SimpleShowLayout>
    </Show>
  </RolePageProvider>
);
