import React, { FC } from 'react';
import { RolePageProps } from './types';
import { Redirect } from 'react-router-dom';

export const RolePageProvider: FC<RolePageProps> = ({
  allowed,
  redirectTo,
  children,
}) => {
  const role = localStorage.getItem('role');
  if (
    role === null ||
    allowed.find(_allowedRole => _allowedRole === role) === undefined
  ) {
    return <Redirect to={redirectTo ? redirectTo : '/calendar'} />;
  } else {
    return <>{children}</>;
  }
};
