import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
const { primary, secondary, header, fontFamily } = {
  primary: { main: '#373f41' },
  secondary: { main: '#022b3a' },
  header: { main: '#CBE5EC' },
  fontFamily: { main: 'Roboto, serif' },
};

const breakpoints = createBreakpoints({});

const custom = {
  RaLayout: {
    root: {
      backgroundColor: '#fff',
    },
    content: {
      padding: '0',
      [breakpoints.up('sm')]: {
        padding: '30px !important',
      },
    },
  },
  RaMenuItemLink: {
    root: {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  RaButton: {
    label: {
      paddingLeft: 0,
    },
  },
};

export const CustomTheme = createMuiTheme({
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  overrides: {
    ...custom,

    //typography
    MuiTypography: {
      h6: {
        fontSize: '1.45rem',
      },
      root: {
        '&.MuiTypography-body1': {
          fontSize: '0.75rem',
        },
      },
    },
    MuiLink: {
      root: {
        color: '#257A8B',
        fontFamily: fontFamily.main,
        fontSize: '.85rem',
      },
    },
    //icons
    MuiIconButton: {
      label: {
        '&.MuiSvgIcon-root': {
          color: '#000',
        },
        '& .MuiSvgIcon-fontSizeSmall': {
          fontSize: '1.4rem',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '17px',
      },
    },
    MuiBadge: {
      anchorOriginTopRightCircle: {
        backgroundColor: primary.main,
        transform: 'scale(1) translate(115%, -50%)',
      },
      badge: {
        borderRadius: '5px !important',
        flexWrap: 'nowrap',
        width: '75px',
      },
      root: {
        width: 'fit-content',
      },
    },

    //buttons
    MuiButton: {
      root: {
        borderRadius: '40px',
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '.1px',
        lineHeight: '22px',
        padding: '10px 20px',
        textTransform: 'none',
        '&.header-button': {
          position: 'absolute',
          right: '20px',
          top: '15px',
          backgroundColor: '#FFFFFF',
          padding: '10px 20px',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
          },
        },
      },
      contained: {
        backgroundColor: '#373f41',
        boxShadow: 'none',
        color: '#fff',
      },
      containedPrimary: {
        backgroundColor: '#373F41',
      },
      label: {
        '& .MuiSvgIcon-root': {
          paddingRight: '8px',
        },
      },
    },

    //accordions
    MuiAccordion: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'rgba(0, 0, 0, 0.5)',
        '&:before': {
          backgroundColor: 'transparent',
        },
        '&.Mui-expanded': {
          margin: '0 0',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '0 16px 0 40px',
        '&.Mui-expanded': {
          minHeight: '0',
        },
      },
      content: {
        '&.Mui-expanded': {
          margin: '0 0',
        },
        '& > p': {
          fontWeight: 700,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        '& > div': {
          width: '100%',
        },
        '& a': {
          paddingLeft: '3.5rem',
        },
      },
    },

    //drawer
    MuiDrawer: {
      root: {
        background: '#F9F9F9',
        paddingLeft: '0',
        paddingTop: '38px',
      },
    },

    //grid
    MuiGrid: {
      container: {
        display: 'grid',
        gridTemplateColumns: '0.55fr 0.45fr',
        gridAutoRows: 'minmax(10px, auto)',
        '&.flex-column': {
          display: 'block',
        },
      },
    },

    //paper
    MuiPaper: {
      rounded: {
        borderRadius: '0',
      },
      elevation1: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      },
    },

    //cards
    MuiCard: {
      root: {
        overflow: 'unset',
        '&.large-card': {
          paddingBottom: '30px',
        },
      },
    },
    MuiCardHeader: {
      title: {
        padding: '10px 30px',
        fontWeight: 700,
      },
      root: {
        backgroundColor: header.main,
        borderBottom: '1px inset #e0e0e0',
        height: '100%',
        padding: '10px',
        '&.pink-header': {
          backgroundColor: '#EDDCF0',
        },
        '&.notes-header': {
          marginBottom: '10px',
        },
      },
    },
    MuiCardContent: {
      root: {
        '&.xl-card-content': {
          padding: '0 !important',
          marginRight: '0 !important',
          minHeight: '100%',
          position: 'relative',
        },
        '& .panelEdit-btn': {
          position: 'absolute',
          right: '10px',
          top: '8px',
          '& > a': {
            background: '#fff',
            color: '#373F41',
            fontSize: '16px',
            padding: '10px 20px',
          },
        },
        minHeight: 'fit-content',
        maxHeight: '100%',
        maxWidth: '1400px',
        padding: '30px',
        '&:first-child': {
          paddingTop: '50px',
        },
      },
    },

    //modal / dialogs
    MuiDialog: {
      paperWidthSm: {
        borderRadius: '0',
        padding: '14px',
        maxWidth: '413px',
      },
    },
    MuiDialogTitle: {
      root: {
        '& h6': {
          fontWeight: 700,
        },
        '& button': {
          right: '15px',
          top: '25px',
          '& .MuiIconButton-label': {
            color: '#000',
          },
        },
      },
    },
    MuiDialogContent: {
      dividers: {
        borderTop: '0',
      },
    },

    //toolbars
    MuiToolbar: {
      dense: {
        '&.dual-show-toolbar': {
          justifyContent: 'flex-end !important',
        },
        '& > svg': {
          position: 'relative',
          top: '-3px',
        },
        '& .RaLoadingIndicator-loadedIcon-16': {
          display: 'none',
        },
        '& button[aria-label="Refresh"]': {
          display: 'none',
        },
        '& .app-loader': {
          position: 'absolute',
          right: '60px',
        },
        '& div > button': {
          padding: '0',
        },
        '& div > button > span > svg': {
          width: '1.4em',
          height: '1.4em',
        },
      },
    },

    //tabs
    MuiTab: {
      root: {
        color: primary.main,
        fontWeight: 'normal',
        padding: '0 25px',
        width: '50%',
        fontSize: '1.2rem',
        textTransform: 'none',
        borderBottom: 'solid 1px',
        borderColor: '#000',
        '&.Mui-selected': {
          backgroundColor: '#FFF',
          borderTop: 'solid 1px',
          borderBottom: 'none',
        },
        '&.Mui-selected:first-child': {
          borderTopRightRadius: '15px',
          borderRight: 'solid 1px',
        },
        '&.Mui-selected:last-child': {
          borderTopLeftRadius: '15px',
          borderLeft: 'solid 1px',
        },
      },
      textColorInherit: {
        opacity: 0.5,
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: '#C4C4C4',
      },
      flexContainer: {
        justifyContent: 'space-around',
      },
      indicator: {
        display: 'none',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#FFF',
      },
    },
    MuiTablePagination: {
      toolbar: {
        background: '#fff',
      },
    },
    MuiTableSortLabel: {
      active: {
        // position: 'initial',
        '&::before': {
          background: '#257A8B',
          content: '""',
          display: 'block',
          height: '3px',
          left: '0',
          position: 'absolute',
          top: '-1px',
          width: '100%',
        },
      },
    },

    //forms
    MuiFormControl: {
      fullWidth: {
        '&.tall-input': {
          height: '100% !important',
          background: '#f2f2f2',
          borderRadius: '5px',
          padding: '7px',
          margin: '10px 0px !important',
        },
      },
      marginDense: {
        marginTop: '0',
        marginBottom: '10px',
      },
      root: {
        '& textarea': {
          backgroundColor: '#FAFAFA',
          border: '0',
          fontFamily: fontFamily.main,
          minHeight: '50px',
          padding: '10px',
          borderRadius: '10px',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '15px',
        '&.Mui-focused': {
          color: '#00000080 !important',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: '#ff0000',
          marginTop: '0',
        },
      },
      contained: {
        marginLeft: '0',
        marginRight: '0',
      },
    },
    MuiInput: {
      root: {
        border: '0',
        borderRadius: '0',
      },
      underline: {
        '&:not(.appointment-type-selector)': {
          '&:before': {
            borderBottom: '0',
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        '& input:not(#searchEntries)': {
          background: '#0000000D',
          border: '0',
          padding: '10px',
          '&.MuiAutocomplete-input': {
            background: '#FFF',
          },
          '&.Mui-disabled': {
            color: '#727272',
          },
        },
        '&.MuiAutocomplete-inputRoot': {
          '&.MuiInput-underline': {
            borderBottom: '1px solid #000',
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            '&:after': {
              borderBottom: 'none',
            },
          },
        },
      },
      input: {
        '& :not(#searchEntries)': {
          height: '0.563em',
          fontSize: '14px',
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'none',
      },
      underline: {
        '&:not(.appointment-type-selector)': {
          '&:before': {
            borderBottom: '0',
          },
          '&.Mui-error:after': {
            borderBottom: '2px solid #ff0000 !important',
          },
        },
      },
    },

    MuiOutlinedInput: {
      input: {
        // border: '1px solid #000',
        padding: '9px 35px 9px 22px',
      },
      adornedEnd: {
        position: 'relative',
        borderRadius: '30px',
        padding: '15px 25px',
        minWidth: '270px',
      },
      notchedOutline: {
        border: 'none',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#000',
        fontFamily: fontFamily.main,
        fontSize: '14px',
        fontWeight: 700,
        letterSpacing: '.5px',
        lineHeight: '17px',
        '& > span': {
          fontWeight: 400,
        },
      },
      outlined: {
        transform: 'none',
        zIndex: 0,
        '&.MuiInputLabel-shrink#searchEntries-label': {
          backgroundColor: '#fff',
          color: '#000000',
          display: 'inline-block',
          top: '2px !important',
          padding: '0 6px !important',
          left: '5px !important',
        },
      },
      filled: {
        '&.MuiInputLabel-marginDense': {
          marginBottom: '10px',
          // transform: 'none',
          transform: 'translate(0, 21px) scale(1)',
          position: 'relative',
        },
        '&.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
          fontSize: '14px',
          marginBottom: '10px',
          // transform: 'none !important',
          position: 'relative',
        },
      },
      formControl: {
        // lineHeight: '100%',
        marginBottom: '0',
        position: 'relative',
        top: '0',
        '&#searchEntries-label': {
          color: '#00000080',
          fontWeight: 400,
          left: '26px',
          marginTop: '0',
          position: 'absolute',
          top: '25%',
        },
      },
    },

    //forms -> select
    MuiSelect: {
      iconFilled: {
        right: '0',
      },
      icon: {
        color: 'rgba(0,0,0,1)',
      },
      filled: {
        borderBottom: '1px solid #000',
        paddingTop: '5px',
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 700,
        marginBottom: '12px',
        marginTop: '7px',
        '&[aria-current*="page"]': {
          color: '#257A8B',
          fontWeight: 700,
        },
        [breakpoints.down('sm')]: {
          padding: '0 16px',
          margin: 0,
          minHeight: '30px',
        },
      },
    },
    //lists
    MuiListItem: {
      gutters: {
        [breakpoints.down('sm')]: {
          paddingLeft: '20px',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      root: {
        '&.Mui-selected': {
          backgroundColor: '#FFF !important',
          '&:hover': {
            backgroundColor: '#FFF !important',
          },
        },
      },
    },
    MuiListItemText: {
      secondary: {
        borderRadius: '10px',
        padding: '15px 15px !important',
        backgroundColor: '#f2f2f2 !important',
        color: '#000',
      },
      primary: {
        padding: '7px 0px',
        fontSize: '15px',
      },
    },
  },

  palette: {
    // common: { black: '#000', white: '#fff' },
    primary: {
      // contrastText: undefined,
      // dark: undefined,
      // light: undefined,
      main: primary.main,
    },
    secondary: {
      // contrastText: undefined,
      // dark: undefined,
      // light: undefined,
      main: secondary.main,
    },
  },
});
