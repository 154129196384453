import React, { FC } from 'react';
import { SVGLogoProps } from './types';

const SVGLogo: FC<SVGLogoProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} style={{ padding: '5px' }} version="1.1">
      <g>
        <symbol id="a">
          <path d="M.613.563a.302.302 0 01-.349.086.282.282 0 01-.155-.16.314.314 0 01-.02-.115C.089.341.093.31.1.282A.263.263 0 01.138.205.29.29 0 01.379.078c.045 0 .088.01.127.028.039.02.075.047.107.085V.078A.411.411 0 00.372 0 .362.362 0 00.16.066a.37.37 0 00-.12.133.395.395 0 00-.014.324.364.364 0 00.085.123.364.364 0 00.266.105.383.383 0 00.12-.02.429.429 0 00.115-.057V.563z" />
        </symbol>
        <symbol id="b">
          <path d="M.382.08a.287.287 0 01.207.087.29.29 0 01.063.322.286.286 0 01-.158.16A.293.293 0 01.11.263.3.3 0 01.38.08m.383.295A.372.372 0 00.382 0a.387.387 0 00-.354.231.37.37 0 000 .289.367.367 0 00.208.202.387.387 0 00.5-.202.373.373 0 00.028-.145z" />
        </symbol>
        <symbol id="c">
          <path d="M.395 0L.173.514.083.028H0L.145.77.395.18l.25.591L.79.028H.707l-.09.486L.394 0z" />
        </symbol>
        <symbol id="d">
          <path d="M.083.32V0H0v.715h.15A.581.581 0 00.266.707.18.18 0 00.33.679.161.161 0 00.383.612a.223.223 0 00.02-.094.223.223 0 00-.02-.094.161.161 0 00-.117-.095A.577.577 0 00.15.321H.083m0 .077h.043c.073 0 .122.008.147.025.025.018.038.048.038.09C.311.56.298.591.272.61.246.628.198.637.126.637H.083v-.24z" />
        </symbol>
        <symbol id="e">
          <path d="M.324.555L.21.292h.228L.324.555m0 .188L.65 0H.557L.464.22H.185L.092 0H0l.324.743z" />
        </symbol>
        <symbol id="f">
          <path d="M.307.415a.24.24 0 00.105-.08A.194.194 0 00.448.22.21.21 0 00.385.06.23.23 0 00.219 0a.229.229 0 00-.142.043A.214.214 0 000 .166l.077.038A.195.195 0 01.128.105a.128.128 0 01.09-.033c.043 0 .078.012.104.037.027.025.04.057.04.096a.13.13 0 01-.028.083.206.206 0 01-.083.06L.164.385a.249.249 0 00-.106.077.184.184 0 00-.035.112C.023.625.042.667.08.7a.21.21 0 00.144.05C.267.75.304.74.335.723A.185.185 0 00.41.643L.34.596A.181.181 0 01.285.66a.116.116 0 01-.067.02.11.11 0 01-.079-.03.1.1 0 01-.03-.074A.1.1 0 01.132.51.196.196 0 01.205.46L.307.415z" />
        </symbol>
        <symbol id="g">
          <path d="M0 0v.715h.085V0H0z" />
        </symbol>
        <symbol id="h">
          <path d="M0 .028V.77L.523.2v.543h.081V0L.081.565V.028H0z" />
        </symbol>
        <symbol id="i">
          <path d="M.202 0v.634H0v.08h.49v-.08H.287V0H.202z" />
        </symbol>
        <symbol id="j">
          <path d="M0 0v.715h.377V.637H.085V.432h.292V.353H.085V.079h.292V0H0z" />
        </symbol>
        <symbol id="k">
          <path d="M0 0v.715h.168A.476.476 0 00.273.707.152.152 0 00.332.679a.163.163 0 00.05-.067A.234.234 0 00.398.52C.399.461.382.417.349.386.318.356.27.341.207.341H.191L.464 0H.356l-.26.34H.082V0H0m.083.643v-.24h.065c.066 0 .11.01.133.026.023.017.034.047.034.09C.315.565.303.597.28.615.257.634.213.643.148.643H.083z" />
        </symbol>
        <use
          href="#a"
          transform="matrix(14.4456 0 0 -14.4456 43.802 33.497)"
          fill={color}
        />
        <use
          href="#b"
          transform="matrix(14.4456 0 0 -14.4456 54.275 33.497)"
          fill={color}
        />
        <use
          href="#c"
          transform="matrix(14.4456 0 0 -14.4456 66.419 33.624)"
          fill={color}
        />
        <use
          href="#d"
          transform="matrix(14.4456 0 0 -14.4456 79.42 33.222)"
          fill={color}
        />
        <use
          href="#e"
          transform="matrix(14.4456 0 0 -14.4456 84.56 33.222)"
          fill={color}
        />
        <use
          href="#f"
          transform="matrix(14.4456 0 0 -14.4456 94.33 33.483)"
          fill={color}
        />
        <use
          href="#f"
          transform="matrix(14.4456 0 0 -14.4456 101.755 33.483)"
          fill={color}
        />
        <use
          href="#g"
          transform="matrix(14.4456 0 0 -14.4456 109.871 33.222)"
          fill={color}
        />
        <use
          href="#b"
          transform="matrix(14.4456 0 0 -14.4456 113.126 33.497)"
          fill={color}
        />
        <use
          href="#h"
          transform="matrix(14.4456 0 0 -14.4456 126.166 33.624)"
          fill={color}
        />
        <use
          href="#e"
          transform="matrix(14.4456 0 0 -14.4456 135.944 33.222)"
          fill={color}
        />
        <use
          href="#i"
          transform="matrix(14.4456 0 0 -14.4456 144.496 33.222)"
          fill={color}
        />
        <use
          href="#j"
          transform="matrix(14.4456 0 0 -14.4456 152.934 33.222)"
          fill={color}
        />
        <use
          href="#a"
          transform="matrix(14.4456 0 0 -14.4456 43.802 49.647)"
          fill={color}
        />
        <use
          href="#e"
          transform="matrix(14.4456 0 0 -14.4456 53.33 49.372)"
          fill={color}
        />
        <use
          href="#k"
          transform="matrix(14.4456 0 0 -14.4456 63.776 49.372)"
          fill={color}
        />
        <use
          href="#j"
          transform="matrix(14.4456 0 0 -14.4456 71.504 49.372)"
          fill={color}
        />
        <clipPath id="l">
          <path transform="matrix(1 0 0 -1 0 58.5)" d="M0 58.5h162V0H0z" />
        </clipPath>
        <g clipPath="url(#l)" fill={color}>
          <path d="M26.363 31.566c.61-.044.936.276 1.642.643.707.374.804 1.072.958 2.152.143 1.075-.27 2.014-.496 2.578-.235.565-1.397 1.084-1.958 1.276-.562.194-2.068-.497-2.445-.872-.38-.373-.394-2.195-.394-2.666-.004-.465.516-1.865 1.107-2.349.23-.184.976-.71 1.586-.762M20.459 37.324c-.858.502-.652.286-1.018 1.46-.367 1.173-.411 1.826-.122 2.574.285.747.76 1.82 1.234 2.006.466.183 1.033.366 1.644.224.603-.143 1.632-.294 1.996-1.608.365-1.312.546-2.39-.116-3.277-.664-.887-2.08-2.278-3.619-1.379M30.587 32.664c-.938.532-.634 2.486-.443 3.372.192.891.714 2.012 2.031 2.28 1.312.274 2.158.405 2.616-.578.46-.989 1.481-2.493.823-3.472-.667-.98-1.428-2.239-2.317-2.324-.89-.09-1.87.247-2.71.722M38.58 37.008c-1.203.097-1.914.576-2.375 1.796-.458 1.222-.68 2.863-.158 3.655.524.79.95 1.49 2.066 1.062 1.124-.43 2.388-.066 2.517-1.706.131-1.634.635-2.717-.032-3.367-.652-.653-.709-1.541-2.018-1.44M26.998 41.21c-.975.564-1.345 1.18-1.997 2.404-.647 1.219-.738 1.504-1.152 1.884-.423.376-.889.849-.972 1.55-.094.703-.272 1.592.433 1.962.707.372.849.746 1.973.6 1.122-.15 1.684-.488 2.2-.345.513.134-.09.515 1.827.453 1.92-.061 3.132-.771 3.505-.631.377.135.151.605 1.368.505 1.211-.108 3.172-.82 3.069-1.85-.1-1.025-.389-2.01-1.374-2.893-.992-.881-1.986-2.378-2.507-3.402-.523-1.025-.618-1.12-1.694-.878-1.077.244-1.26.757-1.921.337-.656-.41-1.779-.264-2.758.305" />
          <path d="M20.259 8.75l-6.175 5.822V9.623H8.85v9.883l-5.286 4.985v25.137h16.09a29.462 29.462 0 00-1.208-1.829c-.763-1.056-2.418-3.16-4.967-6.311-1.794-2.211-3.111-4.155-3.95-5.831-.84-1.677-1.258-3.494-1.258-5.454 0-2.547.827-4.589 2.479-6.121 1.65-1.534 3.642-2.3 5.976-2.3 1.743 0 3.287.553 4.639 1.658 1.35 1.108 2.436 3.118 3.257 6.033.838-2.932 1.939-4.947 3.296-6.045 1.358-1.097 2.9-1.646 4.629-1.646.475 0 .934.04 1.379.105z" />
        </g>
      </g>
    </svg>
  );
};

export default SVGLogo;
