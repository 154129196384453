import React, { FC } from 'react';
import {
  Edit,
  TextInput,
  EditProps,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const HouseholdEdit: FC<EditProps> = props => (
  <RolePageProvider
    allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
    redirectTo="/landing"
  >
    <Edit {...props} basePath="/households" resource="households">
      <SimpleForm redirect={props.basePath}>
        <TextInput validate={[required()]} source="displayName" />
        <TextInput
          validate={[required()]}
          source="addressLine1"
          label="Address Line 1"
        />
        <TextInput source="addressLine2" label="Address Line 2" />
        <TextInput validate={[required()]} source="city" />
        <TextInput validate={[required()]} source="state" />
        <TextInput validate={[required()]} source="postalCode" label="Zip" />
        <TextInput source="location" />
        <ReferenceInput
          label="Primary Contact"
          source="primaryContact"
          reference="owners"
        >
          <SelectInput optionText="firstname" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  </RolePageProvider>
);
