import React, { FC } from 'react';
import { Create, CreateProps, SimpleForm } from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
// eslint-disable-next-line
// @ts-ignore
import { ColorCodeForm } from '../shared/ColorCodeForm';
export const ColorCodeCreate: FC<CreateProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Create {...props}>
      <SimpleForm redirect={props.basePath}>
        <ColorCodeForm />
      </SimpleForm>
    </Create>
  </RolePageProvider>
);
