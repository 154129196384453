import React, { createContext, useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';
import { useDataProvider } from 'react-admin';

import {
  AppointmentEvent,
  DateRange,
  Factory,
  Client,
  Pet,
} from '../shared/types';

export interface SortOptions {
  field: string;
  order: string;
}

export interface FiltersType {
  aftercareLocation?: string;
  doctor?: string;
  dateRange: DateRange;
}

export interface ReportsContext {
  appointmentEvents: Array<AppointmentEvent>;
  pets: Array<Pet>;
  owners: Array<Client>;
  loading: boolean;
  page: number;
  setPage: (page: number) => void;
  filters: FiltersType;
  setFilters: (filters: FiltersType) => void;
  sort: SortOptions;
  setSort: (sort: SortOptions) => void;
}

interface DynamicObject {
  [key: string]: string;
}

const ReportsContext = createContext<ReportsContext>({
  appointmentEvents: [] as Array<AppointmentEvent>,
  pets: [] as Array<Pet>,
  owners: [] as Array<Client>,
  loading: true,
  page: 1,
  setPage: () => null,
  filters: { dateRange: { startDate: moment(), endDate: moment() } },
  setFilters: () => null,
  sort: { field: '', order: '' },
  setSort: () => null,
});

export const ReportsProvider: React.FC = ({ children }) => {
  const reportsContext = useProvidedReportContext();
  return (
    <ReportsContext.Provider value={reportsContext}>
      {children}
    </ReportsContext.Provider>
  );
};

export const useReportsContext: Factory<ReportsContext> = () =>
  useContext(ReportsContext);

const useProvidedReportContext: Factory<ReportsContext> = () => {
  const [appointmentEvents, setAppointmentEvents] = useState<
    Array<AppointmentEvent>
  >([]);
  const [pets, setPets] = useState<Array<Pet>>([]);
  const [owners, setOwners] = useState<Array<Client>>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPageInternal] = useState(1);
  const [filters, setFiltersInternal] = useState<FiltersType>({
    dateRange: {
      startDate: moment().startOf('day').subtract(2, 'weeks'),
      endDate: moment().endOf('day'),
    },
  });
  const [sort, setSortInternal] = useState<SortOptions>({
    field: '',
    order: '',
  });
  const dataProvider = useDataProvider();
  const perPage = 50;

  useEffect(() => {
    const filter = {} as DynamicObject;
    if (filters.aftercareLocation && filters.aftercareLocation !== '') {
      filter.aftercareLocation = filters.aftercareLocation;
    }
    if (filters.doctor && filters.doctor !== '') {
      filter.doctor = filters.doctor;
    }
    filter['startTime[after]'] = filters.dateRange.startDate.format(
      'YYYY-MM-DD',
    );
    filter['endTime[before]'] = filters.dateRange.endDate.format('YYYY-MM-DD');

    dataProvider
      .getList('placeholder_appointment_events', {
        pagination: { page, perPage },
        sort,
        filter,
      })
      .then(({ data }) => {
        setAppointmentEvents(data as Array<AppointmentEvent>);
        setLoading(false);
      });
  }, [dataProvider, sort, filters, page, perPage]);

  useEffect(() => {
    if (appointmentEvents) {
      const ownerIds = appointmentEvents.map(
        ({ owner }: { owner?: string }) => owner,
      );
      const petIds = appointmentEvents.map(({ pet }: { pet?: string }) => pet);
      dataProvider
        .getMany('pets', { ids: petIds as Array<string> })
        .then(({ data }) => {
          setPets(data as Array<Pet>);
        });
      dataProvider
        .getMany('owners', { ids: ownerIds as Array<string> })
        .then(({ data }) => {
          setOwners(data as Array<Client>);
        });
    }
  }, [appointmentEvents, dataProvider]);

  return {
    appointmentEvents,
    pets,
    owners,
    loading,
    page,
    setPage: page => {
      setLoading(true);
      setPageInternal(page);
    },
    filters,
    setFilters: filters => {
      setLoading(true);
      setFiltersInternal(filters);
    },
    sort,
    setSort: sort => {
      setLoading(true);
      setSortInternal(sort);
    },
  };
};
