import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Runnable } from './types';
import { RoleLinkProvider } from './RoleLinkProvider';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      minWidth: '350px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      color: theme.palette.grey[500],
      top: '10px !important',
    },
  });

const customStyles = makeStyles({
  input: {
    '& :not(#searchEntries)': {
      width: '100%',
      marginBottom: '10px',
    },
  },
  button: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '10px 36px',
  },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  color: string | null;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, color, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ backgroundColor: color === null ? 'none' : color }}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogContainer = withStyles(() => ({
  paper: {
    padding: 0,
    maxHeight: '90%',
  },
}))(Dialog);

interface Props {
  title: string;
  closeCallback: Runnable;
  headerColor: string | null;
  buttonText: string | null;
  actionCallback: Runnable;
  buttonEnabled?: boolean;
}

export const ModalDialog: React.FC<Props> = ({
  children,
  title,
  closeCallback,
  headerColor,
  buttonText,
  actionCallback,
  buttonEnabled,
}) => {
  const classes = customStyles();

  return (
    <DialogContainer
      onClose={closeCallback}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeCallback}
        color={headerColor}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
        {buttonText !== null && (
          <RoleLinkProvider
            allowed={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_STAFF']}
          >
            <Button
              disabled={buttonEnabled}
              variant="contained"
              className={classes.button}
              onClick={actionCallback}
            >
              {buttonText}
            </Button>
          </RoleLinkProvider>
        )}
      </DialogContent>
    </DialogContainer>
  );
};
