import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Runnable } from '../shared/types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = makeStyles(theme => {
  return {
    button: {
      fontSize: '16px',
      [theme.breakpoints.up('sm')]: {
        marginLeft: '64px',
        fontSize: '16px',
        fontWeight: 600,
      },
    },
  };
});
interface CreateNewEventButtonProps {
  buttonAction: Runnable;
}

export const CreateNewEventButton: React.FC<CreateNewEventButtonProps> = props => {
  const classes = styles();
  const { buttonAction } = props;
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  return (
    <Button
      className={classes.button}
      id={'createNew'}
      onClick={() => buttonAction()}
    >
      {matchesMobile ? '+ New' : '+ Create New'}
    </Button>
  );
};
