import React, { FC } from 'react';
import {
  BooleanInput,
  Create,
  TextInput,
  CreateProps,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const OwnerCreate: FC<CreateProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}>
    <Create {...props}>
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[required()]}
          source="firstname"
          label="First name"
        />
        <TextInput
          validate={[required()]}
          source="lastname"
          label="Last name"
        />
        <TextInput source="telephone" label="Phone" />
        <TextInput source="alternateTelephone" label="Alternate Phone" />
        <TextInput validate={[required()]} source="email" />
        <BooleanInput source="primary" defaultValue={true} />
        <ReferenceInput
          label="Household"
          source="household"
          reference="households"
        >
          <SelectInput validate={[required()]} optionText="displayName" />
        </ReferenceInput>
        {/*<ReferenceInput*/}
        {/*  label="Events"*/}
        {/*  source="events"*/}
        {/*  reference="events"*/}
        {/*>*/}
        {/*  <SelectInput optionText="notes" />*/}
        {/*</ReferenceInput>*/}
        <BooleanInput source="enabled" defaultValue={true} />
      </SimpleForm>
    </Create>
  </RolePageProvider>
);
