import React, { useEffect, useState } from 'react';
import { post } from '../../utils/Api';
import { Box } from '@material-ui/core';
import { Button, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Pet, PetNotes } from '../shared/types';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment-timezone';

interface PetNotesHistoryProps {
  record?: Pet;
}

const styles = makeStyles({
  note: {
    backgroundColor: '#F0F0DF',
    marginBottom: '10px',
    marginRight: '30px',
    padding: '10px',
    borderRadius: '10px',
    color: '#626262',
    fontFamily: 'Mulish',
    fontWeight: 300,
  },
  meta: {
    fontSize: '13px',
    fontWeight: 700,
  },
  comment: {
    fontSize: '13px',
    fontWeight: 400,
  },
  notesBox: {
    height: '418px',
    overflow: 'auto',
    borderBottom: '1px solid #000000',
  },
  container: {
    width: '90%',
    margin: 'auto',
  },
  toolbar: {
    margin: '10px 0 15px',
    display: 'flex',
    flexDirection: 'column',
    background: '#FAFAFA',
    borderRadius: '10px',
    paddingBottom: '12px',
  },
  formControl: {
    display: 'block',
  },
  inputNote: {
    width: '83%',
  },
  submitButton: {
    backgroundColor: '#373F41',
    margin: '-50px 10px 5px auto',
    marginLeft: 'auto',
    padding: '0.75em 1.5em',
    fontSize: '14px',
    fontWeight: 500,
    color: '#FFF',
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'rgba(55, 63, 65, 0.6) !important',
    },
  },
  inputBottomBorder: {
    width: '95%',
    margin: '0 auto',
    borderBottom: '1px solid #CFCFCF',
  },
});

export const PetNotesHistory: React.FC<PetNotesHistoryProps> = ({ record }) => {
  const [notesList, setNotesList] = useState<Array<PetNotes>>([]);
  const [newNote, setNewNote] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const notify = useNotify();
  const classes = styles();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const onEditorStateChange = (
    editorState: React.SetStateAction<EditorState>,
  ) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setNewNote(html);
  }, [editorState]);

  useEffect(() => {
    setNotesList(record?.petNotes ?? []);
  }, [record]);
  useEffect(() => {
    const noteElement = document.getElementById(`note-list-container`);
    noteElement?.scrollTo({
      behavior: 'smooth',
      top: noteElement.scrollHeight,
    });
  }, [notesList]);
  const postNote = async () => {
    // Disable button after click to prevent duplicated actions
    setButtonDisabled(true);
    const noteBody = {
      note: newNote,
      noteDateTime: new Date(),
      pet: record?.id,
    };
    const respNote = await post({
      endpoint: 'pet_notes',
      body: JSON.stringify(noteBody),
    });

    const noteResponse = (await respNote.json()) as PetNotes;
    if (noteResponse.id) {
      setNewNote('<p></p>');
      setEditorState(EditorState.createEmpty());
      setNotesList([...notesList, noteResponse]);
      record?.petNotes?.push(noteResponse);
      notify('Note saved successfully.', 'info', null, true);
    } else {
      notify('There was an error while saving the Note', 'info', null, true);
    }
    // Enable button after submit
    setButtonDisabled(false);
  };
  const invalid = () => {
    return newNote.trim() === '<p></p>';
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.notesBox} id="note-list-container">
        {notesList
          .sort((a, b) => {
            const aDate = new Date(a.noteDateTime);
            const bDate = new Date(b.noteDateTime);
            return aDate.getTime() - bDate.getTime();
          })
          .map(note => {
            const noteDate = new Date(note.noteDateTime).toLocaleString();
            return (
              <Box
                key={`note-${note.id}`}
                id={`note-${note.id}`}
                className={classes.note}
              >
                <label className={classes.meta}>
                  {note.noteDateTime
                    ? `${moment(noteDate).format('MM/DD/YYYY, h:mm A')}, ${
                        note?.addedBy
                      }: `
                    : ''}
                </label>
                <label
                  className={classes.comment}
                  dangerouslySetInnerHTML={{ __html: note.note }}
                />
              </Box>
            );
          })}
      </Box>
      <div className={classes.toolbar}>
        <Editor
          editorState={editorState}
          spellCheck={true}
          onEditorStateChange={onEditorStateChange}
          editorStyle={{
            maxHeight: '150px',
            paddingLeft: '10px',
            paddingRight: '110px',
            maxWidth: '540px',
            minHeight: '80px',
          }}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'emoji',
            ],
            textAlign: {
              inDropdown: true,
            },
            inline: {
              inDropdown: true,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline', 'monospace'],
            },
            list: {
              inDropdown: true,
            },
            blockType: {
              inDropdown: true,
              options: [
                'Normal',
                'H1',
                'H2',
                'H3',
                'H4',
                'H5',
                'H6',
                'Blockquote',
                // 'Code',
              ],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
          }}
        />
        <Button
          label="Submit"
          onClick={postNote}
          disabled={invalid() || buttonDisabled}
          className={classes.submitButton}
        />
        <div
          className={classes.inputBottomBorder}
          id="input-border-bottom"
        ></div>
      </div>
    </Box>
  );
};
