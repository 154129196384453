import React, { FC } from 'react';
import {
  List,
  TextField,
  ListProps,
  Datagrid,
  EditButton,
  ShowButton,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const HouseholdList: FC<ListProps> = props => (
  <RolePageProvider
    allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}
    redirectTo="/landing"
  >
    <List {...props} hasEdit={true} hasShow={true}>
      <Datagrid>
        <TextField source="displayName" />
        <TextField source="addressLine1" label="Address Line 1" />
        <TextField source="addressLine2" label="Address Line 2" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="location" />
        <TextField source="postalCode" label="Zip" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  </RolePageProvider>
);
