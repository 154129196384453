import { apiUrl } from '../App';

interface Request {
  endpoint: string;
  body?: string;
}

export const get = (request: Request): Promise<Response> => {
  return fetch(`${apiUrl}/${request.endpoint}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

export const post = (request: Request): Promise<Response> => {
  return fetch(`${apiUrl}/${request.endpoint}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: request.body,
  });
};

export const put = (request: Request): Promise<Response> => {
  return fetch(`${apiUrl}/${request.endpoint}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: request.body,
  });
};

export const patch = (request: Request): Promise<Response> => {
  return fetch(`${apiUrl}/${request.endpoint}`, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/merge-patch+json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: request.body,
  });
};

export const remove = (request: Request): Promise<Response> => {
  return fetch(`${apiUrl}/${request.endpoint}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};
