import jwt from 'jsonwebtoken';
import { apiUrl } from './App';
const AuthProvider = {
  login: async ({ username, password }) => {
    const request = new Request(`${apiUrl}/authentication_token`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password: password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ refresh_token, token }) => {
        const { roles, uid, uType } = jwt.decode(token);
        localStorage.setItem('token', token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('role', roles);
        localStorage.setItem('uid', uid);
        localStorage.setItem('uType', uType);
      });
  },
  logout: async () => {
    localStorage.clear();
    return Promise.resolve();
  },
  checkAuth: async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const { exp } = jwt.decode(token);
      if (exp > new Date().getTime() / 1000 - 10) {
        return Promise.resolve();
      }
    }
    return Promise.reject();
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default AuthProvider;
