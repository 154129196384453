import React, { FC, useState } from 'react';
import { useNotify, useLogin, Notification } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { Button, Typography } from '@material-ui/core';
import SVGLogo from './SVGLogo';

const styles = makeStyles({
  layout: {
    height: '1px',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: 'cover',
    justifyContent: 'flex-start',
    backgroundImage:
      'radial-gradient(circle at 50% 14em, #383746 0%, #022b3a 60%, #022b3a 100%)',
    backgroundRepeat: 'no-repeat',
  },
  profile: {
    minWidth: '300px',
    marginTop: '6em',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '10px',
    textAlign: 'center',
  },
  input: {
    marginBottom: '10px',
  },
  notify: {
    justifyContent: 'center',
  },
});

const CustomLogin: FC = () => {
  const classes = styles();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const submit: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    login({ username, password }).catch(() =>
      notify(
        'The username or password does not match our account. Please try again.',
        'error',
      ),
    );
  };

  return (
    <div className={classes.layout}>
      <div className={classes.profile}>
        <Typography variant="body1">
          <SVGLogo color="#000" height="50px" width="160px" />
        </Typography>
        <form>
          <FormControl className="form-control" variant="outlined" fullWidth>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              className={classes.input}
              name="username"
              type="email"
              value={username}
              onChange={event => setUsername(event.target.value)}
            />
          </FormControl>
          <FormControl className="form-control" variant="outlined" fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              required
              className={classes.input}
              name="password"
              type="password"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
          </FormControl>
          <Button
            disabled={password === '' || username === ''}
            disableRipple
            onClick={submit}
            type="submit"
            variant="outlined"
          >
            Submit
          </Button>
        </form>
        <Button onClick={() => history.push('/forgot_password')}>
          Forgot Password?
        </Button>
      </div>
      <Notification className={classes.notify} />
    </div>
  );
};

export default CustomLogin;
