import React, { FC } from 'react';
import { Edit, EditProps, SimpleForm, BooleanInput } from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ColorCodeForm } from '../shared/ColorCodeForm';

export const ColorCodeEdit: FC<EditProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Edit {...props} basePath="/color_codes" resource="color_codes">
      <SimpleForm redirect={props.basePath}>
        <ColorCodeForm />
        <BooleanInput source="deleted" />
      </SimpleForm>
    </Edit>
  </RolePageProvider>
);
