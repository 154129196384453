import React, { FC } from 'react';
import { Show, ShowProps, SimpleShowLayout, Button } from 'react-admin';
import { CardHeader } from '@material-ui/core';
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { GridShowLayout, RaGrid } from 'ra-compact-ui';
import { RolePageProvider } from '../shared/RolePageProvider';
import { OwnerInformationForm } from './OwnerInformationForm';
import AddIcon from '@material-ui/icons/Add';
import { AppointmentHistory } from './OwnerAppointmentHistory';
import { OwnerNotesHistory } from './OwnerNotesHistory';
import { OwnerPets } from './OwnerPets';
import { useHistory } from 'react-router-dom';

export const OwnerShow: FC<ShowProps> = props => {
  const history = useHistory();
  return (
    <RolePageProvider
      {...props}
      allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
    >
      <GridShowLayout {...props}>
        <RaGrid spacing={3} container>
          <RaGrid container item direction="column" className="flex-column">
            <RaGrid className="dual-grid-item" item>
              <OwnerInformationForm {...props} />
            </RaGrid>
            <RaGrid className="dual-grid-item" item>
              <Show {...props} resource="clients" actions={false}>
                <SimpleShowLayout className="xl-card-content">
                  <Button
                    onClick={() => {
                      history.push(`/calendar`);
                    }}
                    label="Create New"
                    className="header-button"
                  >
                    <AddIcon />
                  </Button>
                  <CardHeader title="Appointments" className="pink-header" />
                  <AppointmentHistory {...props} />
                </SimpleShowLayout>
              </Show>
            </RaGrid>
          </RaGrid>
          <RaGrid container item direction="column" className="flex-column">
            <RaGrid className="dual-grid-item" item>
              <OwnerPets {...props} />
            </RaGrid>
            <RaGrid className="dual-grid-item" item>
              <Show {...props} resource="clients" actions={false}>
                <SimpleShowLayout className="xl-card-content">
                  <CardHeader
                    title="Client Notes"
                    className="pink-header notes-header"
                  />
                  <OwnerNotesHistory {...props} />
                </SimpleShowLayout>
              </Show>
            </RaGrid>
          </RaGrid>
        </RaGrid>
      </GridShowLayout>
    </RolePageProvider>
  );
};
