import React, { FC } from 'react';
import {
  Create,
  TextInput,
  CreateProps,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  regex,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const BreedCreate: FC<CreateProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Create {...props} resource="breeds">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Name should not be or start with a space.'),
          ]}
          source="name"
        />
        <ReferenceInput label="Species" source="species" reference="species">
          <SelectInput validate={[required()]} optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  </RolePageProvider>
);
