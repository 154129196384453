import React, { FC, useState } from 'react';
import { ShowProps, Show, SimpleShowLayout, Button } from 'react-admin';
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { GridShowLayout, RaGrid } from 'ra-compact-ui';
import { RolePageProvider } from '../shared/RolePageProvider';
import { PetInformationForm } from './PetInformationForm';
import { PetOwners } from './PetOwners';
import { CardHeader } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { PetNotesHistory } from './PetNotesHistory';
import { PetAppointmentHistory } from './PetAppointmentHistory';
import { useHistory } from 'react-router-dom';
import { AftercareLogBlock } from './AftercareLogBlock';
import EditIcon from '@material-ui/icons/Edit';

export const PetShow: FC<ShowProps> = props => {
  const history = useHistory();
  const [isEditingAC, setIsEditingAC] = useState<boolean>(false);
  return (
    <RolePageProvider
      {...props}
      allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}
    >
      <GridShowLayout {...props}>
        <RaGrid spacing={3} container>
          <RaGrid container item direction="column" className="flex-column">
            <RaGrid className="dual-grid-item" item>
              <PetInformationForm {...props} />
            </RaGrid>
            <RaGrid className="dual-grid-item" item>
              <Show {...props} resource="clients" actions={false}>
                <SimpleShowLayout className="xl-card-content">
                  <Button
                    onClick={() => {
                      setIsEditingAC(!isEditingAC);
                    }}
                    label="Edit"
                    className="header-button"
                  >
                    <EditIcon />
                  </Button>
                  <CardHeader
                    title="Aftercare Status"
                    className="blue-header notes-header"
                  />
                  <AftercareLogBlock
                    {...props}
                    editing={isEditingAC}
                    disableEditing={() => setIsEditingAC(false)}
                  />
                </SimpleShowLayout>
              </Show>
            </RaGrid>
          </RaGrid>
          <RaGrid container item direction="column" className="flex-column">
            <RaGrid className="dual-grid-item" item>
              <PetOwners {...props} />
            </RaGrid>
            <RaGrid className="dual-grid-item" item>
              <Show {...props} resource="clients" actions={false}>
                <SimpleShowLayout className="xl-card-content">
                  <CardHeader
                    title="Pet Notes"
                    className="blue-header notes-header"
                  />
                  <PetNotesHistory {...props} />
                </SimpleShowLayout>
              </Show>
            </RaGrid>
            <RaGrid className="dual-grid-item" item>
              <Show {...props} resource="clients" actions={false}>
                <SimpleShowLayout className="xl-card-content">
                  <Button
                    onClick={() => {
                      history.push(`/calendar`);
                    }}
                    label="Create New"
                    className="header-button"
                  >
                    <AddIcon />
                  </Button>
                  <CardHeader title="Appointments" className="blue-header" />
                  <PetAppointmentHistory {...props} />
                </SimpleShowLayout>
              </Show>
            </RaGrid>
          </RaGrid>
        </RaGrid>
      </GridShowLayout>
    </RolePageProvider>
  );
};
