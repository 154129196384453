import React, { useEffect, useState } from 'react';
import { OwnerModel } from '../../models';
import { get } from '../../utils/Api';
import { Pet } from '../shared/types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
interface PetListProps {
  record?: OwnerModel;
  reload?: boolean;
}

const styles = makeStyles({
  petsContainer: {
    margin: '30px',
  },
  headers: {
    textAlign: 'left' as const,
    color: '#000',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
    paddingBottom: '15px',
  },
  items: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '14px',
    fontWeight: 400,
    padding: '7px 0',
    color: '#626262',
  },
  link: {
    cursor: 'pointer',
    color: '#257A8B',
    textDecoration: 'underline',
    float: 'right',
  },
  table: {
    width: '100%',
    marginBottom: '45px',
    tableLayout: 'fixed',
  },
  petName: {
    cursor: 'pointer',
    color: '#257A8B',
    textDecoration: 'underline',
  },
});

export const PetList: React.FC<PetListProps> = ({ record, reload }) => {
  const [pets, setPets] = useState<Pet[]>([]);
  const history = useHistory();
  const classes = styles();

  useEffect(() => {
    get({
      endpoint: `pets?owners.id=${record?.originId}&order[dateOfDeath]=desc`,
    }).then(async response => {
      const jsonPets = await response.json();
      setPets(jsonPets['hydra:member']);
    });
  }, [record, reload]);

  return (
    <div className={classes.petsContainer}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.headers}>Pet Name</th>
            <th className={classes.headers}>Species</th>
            <th className={classes.headers}>DOD</th>
            <th className={classes.headers}>CC Doctor</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(pets).map(pet => {
            let dod = '';
            if (pet.dateOfDeath) {
              dod = new Date(pet.dateOfDeath).toLocaleString();
              dod = moment(dod).format('MM/DD/YYYY, h:mm A');
            }
            return (
              <tr key={`pet-${pet.id}`}>
                <td
                  className={classes.items}
                  onClick={() => history.push(`/pets/%2Fpets%2F${pet.id}/show`)}
                >
                  <label className={classes.petName}>{pet?.name}</label>
                </td>
                <td className={classes.items}>
                  {pet?.species ? pet?.species.name : pet?.breed?.species.name}
                </td>
                <td className={classes.items}>{pet.dateOfDeath ? dod : ''}</td>
                <td className={classes.items}>
                  {pet.events?.map(event => {
                    return event.user?.name + ' ';
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
