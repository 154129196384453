import {
  Event,
  MemorialOption,
  PetMemorialOption,
} from '../components/shared/types';
import { patch, post } from './Api';

export const getPetMemorialOption = (
  memorialOptions: PetMemorialOption[] | undefined,
  code: string,
): PetMemorialOption => {
  const emptyOption: PetMemorialOption = {
    id: '',
    memorialOption: undefined,
    amount: 0,
    total: 0,
  };
  const res = memorialOptions
    ? memorialOptions.find(value => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return value.memorialOption.code === code;
      })
    : emptyOption;
  if (res === undefined) {
    return emptyOption;
  } else {
    return {
      ...res,
      total: res.amount * (res.memorialOption ? res.memorialOption.price : 0),
    };
  }
};

export const getMemorialOption = (
  memorialOptions: MemorialOption[] | undefined,
  code: string,
): MemorialOption | undefined => {
  return memorialOptions
    ? memorialOptions.find(value => {
        return value.code === code;
      })
    : undefined;
};

export const createMemorialOptionsForPet = (event: Event): boolean => {
  const endpoint = 'pet_memorial_options';
  if (event.pet === undefined) {
    return false;
  }
  event.pet?.memorialOptions.forEach(_mem => {
    if (_mem.memorialOption !== undefined) {
      const body = JSON.stringify({
        amount: _mem.amount,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pet: `/pets/${event.pet.id}`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        memorialOption: `/memorial_options/${_mem.memorialOption.id}`,
      });
      post({
        endpoint: `${endpoint}`,
        body: body,
      });
    }
  });
  return true;
};

export const updateMemorialOptionsForPet = (event: Event): boolean => {
  const endpoint = 'pet_memorial_options';
  if (event.pet === undefined) {
    return false;
  }
  event.pet?.memorialOptions.forEach(_mem => {
    if (_mem.memorialOption !== undefined) {
      const body = JSON.stringify({
        amount: _mem.amount,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pet: `/pets/${event.pet.id}`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        memorialOption: `/memorial_options/${_mem.memorialOption.id}`,
      });
      patch({
        endpoint: `${endpoint}/${_mem.id}`,
        body: body,
      });
    }
  });
  return true;
};

export const getTotalAftercareCharge = (event: Event | undefined): number => {
  let total = 0;
  if (event !== undefined) {
    total =
      (event.aftercareCharge ?? 0) +
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getPetMemorialOption(
        event.pet ? event.pet?.memorialOptions : undefined,
        'CP',
      ).total +
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getPetMemorialOption(
        event.pet ? event.pet?.memorialOptions : undefined,
        'IP',
      ).total +
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getPetMemorialOption(
        event.pet ? event.pet?.memorialOptions : undefined,
        'NP',
      ).total;
  }

  return total;
};
